import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';

import { AccountService } from './services/account.service';

@Component({
    selector: 'hd-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {

    loadingPage$ = this.router.events
        .pipe(
            filter(event =>
                event instanceof NavigationStart ||
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError,
            ),
            map(event => event instanceof NavigationStart),
        );

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
    ) {}

    ngOnInit() {
        this.accountService.refreshToken()
            .subscribe();

        if ((environment as any).googleTrackingCode) {
            this.setupGoogleAnalytics((environment as any).googleTrackingCode);
        }

        const scrollLevels = {};
        let lastId = 0;
        let restoredId: number;

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                scrollLevels[lastId] = window.scrollY;
                lastId = event.id;
                restoredId = event.restoredState ? event.restoredState.navigationId : undefined;
            }

            if (event instanceof NavigationEnd) {
                if (restoredId) {
                    setTimeout(() => window.scrollTo(0, scrollLevels[restoredId] || 0))
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });
    }

    ngAfterViewInit() {
        this.setViewportHeight();
    }

    @HostListener('window:resize')
    setViewportHeight() {
        document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
    }

    private setupGoogleAnalytics(googleTrackingCode: string) {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleTrackingCode}`;
        document.head.prepend(script);

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                (window as any).gtag('config', googleTrackingCode, { page_path: urlAfterRedirects });
            });
    }
}
<div [@fadeIn]>

    <h1 class="title">Privacy Policy</h1>

    <p class="text">Last updated: 15/05/2022</p>

    <p class="text">
        Thank you for choosing to be part of our community at be local (“company”, “we”, “us”, or “our”).
        We are committed to protecting your personal information and your right to privacy. If you have
        any questions or concerns about our policy, or our practices with regards to your personal
        information, please contact us at <a href="mailto:graham@be-local.uk">graham@be-local.uk</a>
    </p>

    <p class="text">
        When you visit our website and use our services, you trust us with your personal information.
        We take your privacy very seriously. We will explain to you in the clearest way possible what
        information we collect, how we use it and what rights you have in relation to it. We hope you
        take some time to read through it carefully, as it is important. If there are any terms in
        this privacy policy that you do not agree with, please discontinue use of our website,
        membership and contact us.
    </p>

    <p class="text">
        This privacy policy applies to all information collected through our and/or any related
        services, sales, marketing or events (we refer to them collectively in this privacy policy
        as the "Sites").
    </p>

    <p class="text">
        Please read this privacy policy carefully as it will help you make informed decisions about
        sharing your personal information with us.
    </p>

    <ol>

        <li class="text">
            <strong>What information do we collect?</strong>

            <p>We need information from you in order to join the be local membership.</p>

            <p>The information we collect will include:</p>
            <ul>
                <li>Name(s)</li>
                <li>Username – normally your email address</li>
                <li>Contact Details (postal address, email address)</li>
                <li>Password</li>
                <li>Postcode</li>
            </ul>

            <p>
                We DO NOT collect or hold your Payment Card Details These are collected, used and
                processed via Stripe, a third party payment processor, in accordance with their
                privacy policy. Stripe’s services in Europe are provided by a Stripe affiliate—Stripe
                Payments Europe Limited (“Stripe Payments Europe”)—an entity located in Ireland.
                In providing Stripe Services, Stripe Payments Europe transfers personal data to
                Stripe, Inc. in the US.
            </p>

            <p>
                All personal information that you provide to us must be true, complete and accurate,
                and you must notify us of any changes to such personal information.
            </p>

        </li>

        <li class="text">
            <strong>Will your information be shared with anyone?</strong>

            <p>
                We will not share your personal details with third parties. The only data which
                we will share with third parties is not identifiable and includes non-specific
                (non PII) demographics, website usage and location. We only share your information
                with your consent, to comply with laws, to protect your rights, or to fulfil
                business obligations. We may process your data if you have given us specific
                consent to use your personal information for a specific purpose.
            </p>
        </li>

        <li class="text">
            <strong>Do we use cookies and similar technologies?</strong>

            <p>
                We collect information through the use of cookies and similar technologies, to enable
                us to remember when you visit our websites and to improve your online experience.
            </p>

            <p>
                When you receive emails from us, we may use technology or links to determine how you use
                our emails, and your interest in it, so we can send you offers that are relevant to you.
            </p>

            <p>
                Specific information about how we use Cookies and similar technologies can be found in
                our Cookie Policy.
            </p>

        </li>

        <li class="text">
            <strong>How long do we keep your information?</strong>

            <p>
                We keep your information for as long as necessary to fulfil the purposes outlined in this
                privacy policy unless otherwise required by law.
            </p>
        </li>

        <li class="text">
            <strong>How do we keep your information safe?</strong>

            <p>
                We have implemented appropriate technical and security measures designed to protect the
                security of any personal information we process. However, please also remember that we
                cannot guarantee that the internet itself is 100% secure. Although we will do our best
                to protect your personal information, transmission of personal information to and from
                our website is at your own risk. You should only access the services within a secure
                environment.

            </p>
        </li>

        <li class="text">
            <strong>What are your privacy rights?</strong>

            <p>
                YYou have a number of rights with regards to the personal information that we hold about
                you, which you will have provided as part of registering to be a be local member.
            </p>

            <p>
                Right of Access - You have the right to request a copy of the information that we hold
                about you. We will provide you with this information within one month of receiving your
                request and verifying your identity.
            </p>

            <p>
                Right to Rectification - We want to make sure that your personal information is accurate
                and up to date. You may ask us to correct, update or remove information you think is inaccurate
                or incomplete, and we ask that you inform us of any changes.

            <p>
                Right to Erasure - You may also ask us to erase your personal information from our systems,
                in certain circumstances. There are some specific circumstances where the right to erasure does
                not apply and we are permitted to hold your data. We will explain the reason for this at the time,
                should this occur.
            </p>

            <p>
                Right to Data Portability - You may also ask us to move, or ‘port’, your personal information
                to another organisation electronically. We will only port personal information you have provided
                to us, that we have processed based on your consent or performance of a contract, or that has been
                processed automatically. We will port your personal information without charge and within one month,
                where technically feasible.
            </p>

            <p>
                Right to Restricting Processing – You have a right to request that we restrict the processing
                of your personal data in certain circumstances. We will inform you when the restriction on
                processing your personal data ends.
            </p>

            <p>
                If you would like to exercise these rights, please email
                <a href="mailto:graham@be-local.uk">graham@be-local.uk</a>
            </p>
        </li>

        <li class="text">
            <strong>Do we make updates to this privacy policy?</strong>

            <p>
                We may update this privacy policy from time to time. The updated version will be indicated by
                an updated “Revised” date and the updated version will be effective as soon as it is accessible.
                We encourage you to review this privacy policy frequently to be informed of how we are
                protecting your information.

            </p>
        </li>

        <li class="text">

            <strong>How can I unsubscribe from marketing emails?</strong>

            <p>
                We would like to send you information to help you get the most out of your membership and to
                you up to date with the very latest from our independents. We will collect your information
                for marketing purposes, if you agree, when you register to join be local. To stop us contacting
                you for marketing email <a href="mailto:graham@be-local.uk">graham@be-local.uk</a>

            </p>
        </li>

        <li class="text">
            <strong>How can I terminate my be local membership?</strong>

            <p>
                Of course, we’d be sad to see you leave and we’re always looking for feedback as to why you’re
                leaving. Cancelling your monthly direct debit via Stripe on our website is simple. Visit Manage
                my Subscription from there you can cancel your membership. As long as this is done 48 hours
                before your next monthly payment is due it will be cancelled at the end of your billing period.
            </p>

            <p>
                If you have any feedback on how we can improve our membership club we’d love to hear from you,
                please email <a href="mailto:graham@be-local.uk">graham@be-local.uk</a>

            </p>
        </li>

        <li class="text">
            <strong>Will my information be transferred outside the European Economic Area?</strong>

            <p>
                Currently, the only non-EEA country where be local share your information with is the
                United States via Stripe.
            </p>

            <p>
                We will also transfer your information outside the EEA or to an international organisation
                in order to comply with legal obligations to which we are subject (compliance with a court
                order, for example). Where we are required to do so, we will ensure appropriate safeguards
                and protections are in place.
            </p>

            <p>
                Stripe has self-certified their compliance with the EU-U.S. Privacy Shield.
            </p>
        </li>

        <li class="text">
            <strong>How can I contact you about this policy?</strong>

            <p>
                If you have questions or comments about this policy, you may email us at
                <a href="mailto:graham@be-local.uk">graham@be-local.uk</a>
            </p>
        </li>

    </ol>

</div>

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { trigger } from '@angular/animations';

import { EstablishmentService } from '../../services/establishment.service';
import { fadeInAnimation } from '../../animations/fade-in';
import { Fulfilment } from '../../models/fulfilment.model';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountService } from '../../services/account.service';
import { mapStyles } from '../../models/map.styles';
import { Establishment } from '../../models/establishment.model';

@Component({
    selector: 'hd-establishment-page',
    templateUrl: './establishment-page.component.html',
    styleUrls: [ './establishment-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ]
})
export class EstablishmentPageComponent implements OnInit {

    mapStyles = mapStyles;
    url = this.router.url;
    authenticated$ = AccountService.authenticated$;

    static FULFILMENTS = {
        1: 'Delivery',
        2: 'Takeaway',
        3: 'Visit',
    };

    imagesLoaded = {};
    establishment: Establishment;
    establishmentLoading = false;
    openingTimes: {
        day: string;
        open: string;
        close: string;
        isToday: boolean;
    }[] = [];

    get instagramUsername() {
        return this.establishment?.instagramUrl &&
            `@${/[^/]*$/.exec(this.establishment.instagramUrl.replace(/\/$/, ''))[0]}`;
    }

    get addressUrl() {
        return this.establishment &&
            this.establishment.address &&
            this.sanitizer.bypassSecurityTrustUrl(`https://www.google.com/maps?q=${this.establishment.name}, ${this.establishment.address.addressLine1}, ${this.establishment.address.postcode}`);
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private establishmentService: EstablishmentService,
    ) {}

    ngOnInit() {
        this.establishmentLoading = true;

        this.establishmentService.getOne(this.route.snapshot.params.slug)
            .subscribe(establishment => {
                this.establishmentLoading = false;
                this.establishment = establishment;

                this.setOpeningTimes(establishment);
            });
    }

    fulfilmentOptions(fulfilments: Fulfilment[]) {
        return Object.entries(EstablishmentPageComponent.FULFILMENTS)
            .map(([ key, fulfilment ]) => ({
                key,
                fulfilment,
                available: fulfilments.includes(Number(key)),
            }));
    }

    private setOpeningTimes({ showOpeningTimes, openingTimes }: Establishment) {
        if (!showOpeningTimes) {
            return;
        }

        const today = new Date();

        this.openingTimes = openingTimes.map(({ day, open, close }) => ({
            isToday: {
                monday: 1,
                tuesday: 2,
                wednesday: 3,
                thursday: 4,
                friday: 5,
                saturday: 6,
                sunday: 7,
            }[day] === today.getDay(),
            day: {
                monday: 'Mon',
                tuesday: 'Tue',
                wednesday: 'Wed',
                thursday: 'Thu',
                friday: 'Fri',
                saturday: 'Sat',
                sunday: 'Sun',
            }[day],
            open: (() => {
                const [ hours, minutes ] = open.split(':');
                const time = new Date(null, null, null, parseInt(hours, 10), parseInt(minutes, 10));

                if (isNaN(time as any)) {
                    return undefined;
                }

                return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).replace(' ', '');
            })(),
            close: (() => {
                const [ hours, minutes ] = close.split(':');
                const time = new Date(null, null, null, parseInt(hours, 10), parseInt(minutes, 10));

                if (isNaN(time as any)) {
                    return undefined;
                }

                return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).replace(' ', '');
            })(),
        }));
    }

    fulfilmentTrackBy(index) {
        return index;
    }

    onTrack(category: string) {
        if (!(window as any).gtag) {
            return;
        }

        (window as any).gtag('event', 'click', {
            event_category: `Establishment Page`,
            event_label: this.establishment.name,
            event_action: `Click ${category}`,
        });
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EstablishmentBlockComponent } from './establishment-block.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        EstablishmentBlockComponent,
    ],
    exports: [
        EstablishmentBlockComponent,
    ],
})
export class EstablishmentBlockModule {}

<div [@fadeIn]>
    <h1 class="title">Download the app</h1>

    <p class="text">
        Download our multi-award winning app today. Featuring discounts up to 25% off from over 100 independents
        plus lots of circular walks
    </p>

    <img
        src="/assets/images/app-images.png"
        class="image"
    >

    <p class="text">
        It's your handy go-to guide for discovering the very best of Solihull. Join today for just £1.99 a month
        for instant access to great deals.
    </p>

    <div class="buttons">
        <a
            href="https://apps.apple.com/gb/app/home-dine/id1550837261"
            class="button"
            target="_blank"
        >
            <img
                src="/assets/images/download-on-app-store.svg"
                class="button__image"
            >
        </a>

        <a
            href="https://play.google.com/store/apps/details?id=uk.co.homeDine.homeDine&hl=en&gl=gb"
            class="button"
            target="_blank"
        >
            <img
                src="/assets/images/get-it-on-google-play.png"
                class="button__image"
            >
        </a>
    </div>
</div>

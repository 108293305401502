import { BehaviorSubject, EMPTY, from, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

import jwtDecode from 'jwt-decode';
import { Area } from '../models/area.model';

@Injectable({
    providedIn: 'root',
})
export class AccountService {

    // Stripe instances must be reused else it'll error creating payment method
    static stripeInstances = {};
    static AUTH_TOKEN_KEY = 'be-local.auth.token';
    static authenticating$ = new BehaviorSubject(false);
    static user$ = new BehaviorSubject(null);
    static authenticated$ = AccountService.user$.pipe(map(user => Boolean(user)));

    private refreshTokenTimeout;

    constructor(
        private router: Router,
        private http: HttpClient,
    ) {}

    create(account: {
        firstName: string,
        lastName: string,
        email: string,
        area: Area,
        location: string,
        password: string,
        terms: string,
        comms: string,
    }) {
        return this.http.post<{ token: string; refreshToken: string; accessToken: string; }>('https://api.be-local.uk/account/create', {
            ...account,
            email: account.email.toLowerCase(),
            stripeAccountId: account.area.stripeAccountId,
        })
            .pipe(
                tap(({ token, refreshToken, accessToken }) => {
                    AccountService.storeToken(token);
                    AccountService.storeRefreshToken(refreshToken);
                    AccountService.storeAccessToken(accessToken);

                    // ENSURE TOKENS ARE SAVED BEFORE BROADCASTING USER
                    AccountService.user$.next(jwtDecode(token));

                    this.startRefreshTimer();
                }),
                catchError(({ error }) => throwError(error)),
            );
    }

    refreshToken() {
        if (!AccountService.hasRefreshToken()) {
            AccountService.removeSession();

            return EMPTY;
        }

        AccountService.authenticating$.next(true);

        return this.http.post<{ token: string; accessToken: string; }>('https://api.be-local.uk/account/refresh-token', { refreshToken: AccountService.getRefreshToken() })
            .pipe(
                tap({
                    next: ({ token, accessToken }) => {
                        AccountService.authenticating$.next(false);

                        AccountService.storeToken(token);
                        AccountService.storeAccessToken(accessToken);

                        AccountService.user$.next(jwtDecode(token));

                        this.startRefreshTimer();
                    },
                }),
            );
    }

    private startRefreshTimer() {
        const expires = new Date(AccountService.user$.value.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);

        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    login(email: string, password: string) {
        return this.http.post<{ token: string; refreshToken: string; accessToken: string; }>('https://api.be-local.uk/account/login', {
            email,
            password,
        })
            .pipe(
                tap(({ token, refreshToken, accessToken }) => {
                    AccountService.storeToken(token);
                    AccountService.storeRefreshToken(refreshToken);
                    AccountService.storeAccessToken(accessToken);

                    AccountService.user$.next(jwtDecode(token));
                }),
            )
    }

    logout() {
        this.stopRefreshTokenTimer();

        AccountService.removeSession();

        this.router.navigate(['/login']);

        return of(true);
    }

    createCustomerPortalSession() {
        return this.http.post<{ url: string; }>('https://api.be-local.uk/account/customer-portal-session', null, {
            headers: {
                authorization: `Bearer ${AccountService.getToken()}`,
            },
        })
            .pipe(map(({ url }) => url))
    }

    resetPasswordRequest(email: string) {
        return this.http.post<{ token: string; refreshToken: string; }>('https://api.be-local.uk/account/reset-password-request', {
            email,
        });
    }

    resetPassword(email: string, verificationCode: string, newPassword: string) {
        return this.http.post<{ token: string; refreshToken: string; }>('https://api.be-local.uk/account/reset-password', {
            email,
            verificationCode,
            newPassword,
        });
    }

    // @TODO CHANGE TO USE NEW APIS
    changePassword(oldPassword: string, newPassword: string) {
        return this.http.post<any>('https://api.be-local.uk/account/change-password', {
            oldPassword,
            newPassword,
            accessToken: AccountService.getAccessToken(),
        });
    }


    getPromotionalCode(promotionalCode: string) {
        return this.http.get<any>(`https://api.be-local.uk/account/promotional-code/${promotionalCode}`, {
            headers: {
                authorization: `Bearer ${ AccountService.getToken() }`,
            }
        });
    }

    createPaymentMethod(card: any, promotionalCodeId: string) {
        const stripe = AccountService.getStripeInstance(AccountService.user$.value['custom:stripeAccountId']);

        return from(stripe.createPaymentMethod({
            type: 'card',
            card: card,
        }))
            .pipe(switchMap((result: any) => {
                if (result.error) {
                    throw result;
                }

                return this.setupSubscription({
                    paymentMethodId: result.paymentMethod.id,
                    promotionalCodeId,
                });
            }));
    }

    setupSubscription({ paymentMethodId, promotionalCodeId }) {
        return this.http.post<{
            subscription: any;
            invoice: any;
            token: string;
            refreshToken: string;
            accessToken: string;
            isRetry: boolean;
        }>('https://api.be-local.uk/account/subscribe', {
            paymentMethodId,
            promotionalCodeId,
            refreshToken: AccountService.getRefreshToken(),
        }, {
            headers: { authorization: `Bearer ${AccountService.getToken()}` }
        })
            .pipe(
                tap(({ token, refreshToken, accessToken }) => {
                    AccountService.storeToken(token);
                    AccountService.storeRefreshToken(refreshToken);
                    AccountService.storeAccessToken(accessToken);

                    AccountService.user$.next(jwtDecode(token));
                }),
                catchError(({ error }) => throwError(error)),
                map(({ subscription, invoice, isRetry }) => {
                    if (subscription && subscription.error) {

                        // The card had an error when trying to attach it to a customer
                        throw subscription;
                    }

                    return {
                        subscription,
                        invoice,
                        paymentMethodId,
                        isRetry,
                    };
                }),
                switchMap(params => this.handleCustomerActionRequired(params)),
                switchMap(params => this.handlePaymentMethodRequired(params)),
                switchMap(() => this.onSubscriptionComplete()),
            );
    }

    handleCustomerActionRequired({ subscription, invoice, paymentMethodId, isRetry }) {
        const stripe = AccountService.getStripeInstance(AccountService.user$.value['custom:stripeAccountId']);

        if (subscription && subscription.status === 'active') {

            // subscription is active, no customer actions required.
            return of({
                subscription,
                paymentMethodId,
            });
        }

        const paymentIntent = invoice
            ? invoice.payment_intent
            : subscription.latest_invoice.payment_intent;

        if (paymentIntent.status === 'requires_action' || (isRetry && paymentIntent.status === 'requires_payment_method')) {
            return from(stripe
                .confirmCardPayment(paymentIntent.client_secret, {
                    payment_method: paymentMethodId,
                }))
                .pipe(map((result: any) => {
                    if (result.error) {

                        // start code flow to handle updating the payment details
                        // Display error message in your UI.
                        // The card was declined (i.e. insufficient funds, card has expired, etc)
                        throw result;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {

                            // There's a risk of the customer closing the window before callback
                            // execution. To handle this case, set up a webhook endpoint and
                            // listen to invoice.paid. This webhook endpoint returns an Invoice.
                            return {
                                subscription,
                                invoice,
                                paymentMethodId,
                            };
                        }
                    }
                }));
        } else {

            // No customer action needed
            return of({
                subscription,
                paymentMethodId,
            });
        }
    }

    handlePaymentMethodRequired({ subscription, paymentMethodId }) {
        if (subscription) {
            if (subscription.status === 'active') {

                // subscription is active, no customer actions required.
                return of({
                    subscription,
                    paymentMethodId,
                });
            }

            if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
                throw { error: { message: 'Your card was declined.' } };
            }
        }

        return of({
            subscription,
            paymentMethodId,
        });
    }

    onSubscriptionComplete() {
        // Payment was successful. Provision access to your service.
        // Remove invoice from localstorage because payment is now complete.

        // Change your UI to show a success message to your customer.

        // Call your backend to grant access to your service based on
        // the product your customer subscribed to.
        // Get the product by using result.subscription.price.product

        return this.http.post<{ token: string; refreshToken: string; accessToken: string; }>('https://api.be-local.uk/account/provision', {
            refreshToken: AccountService.getRefreshToken(),
        }, {
            headers: { authorization: `Bearer ${AccountService.getToken()}` }
        })
            .pipe(tap(({ token, refreshToken, accessToken }) => {
                AccountService.storeToken(token);
                AccountService.storeRefreshToken(refreshToken);
                AccountService.storeAccessToken(accessToken);

                AccountService.user$.next(jwtDecode(token));
            }));
    }

    static removeSession() {
        AccountService.removeToken();
        AccountService.removeRefreshToken();
        AccountService.removeAccessToken();

        AccountService.authenticating$.next(false);
        AccountService.user$.next(null);
    }

    static getStripeInstance(accountId: string) {
        if (!AccountService.stripeInstances[accountId]) {

            // @ts-ignore Stripe is global
            AccountService.stripeInstances[accountId] = Stripe('pk_live_51GvhWBHM14YvCzvf9Z2lu6HlqLHrZB3vKit2zgZ2kuM61OTAaEtMb0YvjkwUJl9w1sbBl3IjxDjzpFoBQKA7cLHv00e8NwJf34', {
                stripeAccount: accountId
            });
        }

        return AccountService.stripeInstances[accountId];
    }

    static isAuthenticated() {
        try {
            jwtDecode(AccountService.getToken())

            return true;
        } catch {
            return false;
        }
    }

    static isSubscribed() {
        try {
            const token = jwtDecode(AccountService.getToken());

            return Boolean(Number(token['custom:stripeSubActive']));
        } catch {
            return false;
        }
    }

    static hasToken() {
        return AccountService.getToken() !== null;
    }

    static hasRefreshToken() {
        return AccountService.getRefreshToken() !== null;
    }

    static getToken() {
        try {
            return localStorage.getItem(AccountService.AUTH_TOKEN_KEY);
        } catch {
            return null;
        }
    }

    static getRefreshToken() {
        try {
            return localStorage.getItem('be-local.auth.refreshToken');
        } catch {
            return null;
        }
    }

    static getAccessToken() {
        try {
            return localStorage.getItem('be-local.auth.accessToken');
        } catch {
            return null;
        }
    }

    static getTokenContents() {
        try {
            return jwtDecode(AccountService.getToken());
        } catch {
            return null;
        }
    }

    static stripeCustomerId() {
        const contents = this.getTokenContents();

        return contents && ['custom:stripeCustomerId'];
    }

    static stripeSubscriptionId() {
        const contents = this.getTokenContents();

        return contents && ['custom:stripeSubscriptionId'];
    }

    static stripeSubscriptionActive() {
        const contents = this.getTokenContents();

        return contents && Boolean(Number(this.getTokenContents()['custom:stripeSubActive']));
    }

    static storeToken(token: string) {
        try {
            localStorage.setItem(AccountService.AUTH_TOKEN_KEY, token);
        } catch {
            // Cannot write to localStorage
        }
    }

    static storeRefreshToken(refreshToken: string) {
        try {
            localStorage.setItem('be-local.auth.refreshToken', refreshToken);
        } catch {
            // Cannot write to localStorage
        }
    }

    static storeAccessToken(accessToken: string) {
        try {
            localStorage.setItem('be-local.auth.accessToken', accessToken);
        } catch {
            // Cannot write to localStorage
        }
    }

    static removeToken() {
        try {
            localStorage.removeItem(AccountService.AUTH_TOKEN_KEY);
        } catch {
            // Cannot remove from localStorage
        }
    }

    static removeRefreshToken() {
        try {
            localStorage.removeItem('be-local.auth.refreshToken');
        } catch {
            // Cannot remove from localStorage
        }
    }

    static removeAccessToken() {
        try {
            localStorage.removeItem('be-local.auth.accessToken');
        } catch {
            // Cannot remove from localStorage
        }
    }
}

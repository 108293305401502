import { Injectable } from '@angular/core';

import { ApiRequestService } from './api-request.service';
import { Area } from '../models/area.model';
import { Blog } from '../models/blog.model';

@Injectable({
    providedIn: 'root',
})
export class BlogService {

    constructor(private apiRequest: ApiRequestService) {}

    getAll(area: Area) {
        return this.apiRequest.get<Blog[]>(`https://api.be-local.uk/blogs?areaId=${area._id}`, { useCache: true });
    }

    getOne(slug: string) {
        return this.apiRequest.get<Blog>(`https://api.be-local.uk/blogs/${slug}`, { useCache: true });
    }

}

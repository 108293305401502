<div [@fadeIn]>

    <h1 class="title">Reset Password</h1>

    <p class="text">
        You'll be able to reset your password using a reset code that we'll send to your email
    </p>

    <form
        *ngIf="action === 'REQUEST'"
        (submit)="onRequest(requestForm)"
    >

        <hd-message-block
            *ngIf="error"
            severity="error"
        >
            Sorry, there was an issue resetting your password
        </hd-message-block>

        <hd-form-group>
            <hd-email-input
                errorMessage="Please enter a valid email"
                label="Email"
                name="email"
                [control]="requestEmailControl"
            ></hd-email-input>
        </hd-form-group>

        <button
            class="button"
            [disabled]="resetting"
        >

            <ng-container *ngIf="!resetting">
                Reset password
            </ng-container>

            <hd-loader
                *ngIf="resetting"
                [small]="true"
                [flush]="true"
            ></hd-loader>

        </button>
    </form>

    <form
        *ngIf="action === 'RESET'"
        (submit)="onReset(resetForm)"
    >

        <hd-message-block>
            A reset code was sent to {{ emailToBeReset }}
        </hd-message-block>

        <hd-message-block
            *ngIf="error"
            severity="error"
        >
            <ng-container *ngIf="invalidCode">
                Please ensure you have entered the correct verification code
            </ng-container>

            <ng-container *ngIf="!invalidCode">
                Sorry, there was an issue resetting your password. Please try again later
            </ng-container>
        </hd-message-block>

        <hd-form-group>
            <hd-text-input
                errorMessage="Please enter your verification code"
                label="Verification code"
                name="verificationCode"
                [control]="resetVerificationCodeControl"
            ></hd-text-input>
        </hd-form-group>

        <hd-form-group>
            <hd-password-input
                errorMessage="Please enter a valid password"
                label="Password"
                name="password"
                [control]="resetNewPasswordControl"
                [validate]="true"
                [showRules]="true"
            ></hd-password-input>
        </hd-form-group>

        <button
            class="button"
            [disabled]="resetting"
        >

            <ng-container *ngIf="!resetting">
                Reset password
            </ng-container>

            <hd-loader
                *ngIf="resetting"
                [small]="true"
                [flush]="true"
            ></hd-loader>

        </button>
    </form>

</div>
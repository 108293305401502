import {
    AfterContentInit,
    AfterViewInit, ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef, EventEmitter, HostBinding, Input, Output,
    QueryList,
    ViewChild
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { debounceTime, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

import { BaseComponent } from '../../base-component.class';
import { CarouselItemComponent } from '../carousel-item/carousel-item.component';

@Component({
    selector: 'hd-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: [ './carousel.component.scss' ],
})
export class CarouselComponent extends BaseComponent implements AfterContentInit, AfterViewInit {

    @HostBinding('class.snap') @Input() snap = false;

    @Output() activeItem = new EventEmitter<number>();

    @ViewChild('scroll', { static: false }) scroll: ElementRef;
    @ViewChild('cards', { static: false }) cards: ElementRef;
    @ContentChildren(CarouselItemComponent, { read: ElementRef }) items: QueryList<ElementRef>;

    showLeftArrow = false;
    showRightArrow = true;
    offScreen = true;
    scrollActiveItemChangeEnabled = false;

    constructor(
        private element: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        private breakpointObserver: BreakpointObserver,
    ) {
        super();
    }

    ngAfterContentInit() {
        this.items.changes
            .subscribe(() => {
                this.scroll.nativeElement.scrollTo(0, 0);
            });
    }

    ngAfterViewInit() {
        this.breakpointObserver.observe([ '(max-width: 599px)' ])
            .pipe(
                takeUntil(this.destroyed$),
                map(({ matches }) => matches)
            );

        fromEvent(this.scroll.nativeElement, 'scroll')
            .pipe(
                debounceTime(50),
                takeUntil(this.destroyed$),
                withLatestFrom(this.breakpointObserver.observe([ '(max-width: 599px)' ])
                    .pipe(
                        takeUntil(this.destroyed$),
                        map(({ matches }) => matches)
                    ),
                ),
                filter(([ , scrollActiveItemChangeEnabled]) => scrollActiveItemChangeEnabled),
            )
            .subscribe(([ { target: { scrollLeft } } ]: any[]) => {
                this.activeItem.emit(Math.round(scrollLeft / this.cards.nativeElement.children[0].offsetWidth) + 1);
            });

        this.scroll.nativeElement
            .addEventListener('scroll', () => {
                this.setArrowsVisible();
            });

        this.setArrowsVisible();
        this.changeDetectorRef.detectChanges();

        const observer = new IntersectionObserver(([ entry ]) => {
            if (!entry.isIntersecting) {
                return;
            }

            this.offScreen = false;

            observer.unobserve(this.element.nativeElement);

        }, { threshold: 0.6 });

        observer.observe(this.element.nativeElement);
    }

    setArrowsVisible() {
        this.showLeftArrow = this.scroll.nativeElement.scrollLeft > 0;
        this.showRightArrow = this.scroll.nativeElement.scrollLeft + this.scroll.nativeElement.offsetWidth < this.scroll.nativeElement.scrollWidth;
    }

    onScrollRight() {
        this.scroll.nativeElement.scrollTo({
            top: 0,
            left: this.scroll.nativeElement.scrollLeft + window.innerWidth,
            behavior: 'smooth',
        });
    }

    onScrollLeft() {
        this.scroll.nativeElement.scrollTo({
            top: 0,
            left: this.scroll.nativeElement.scrollLeft - window.innerWidth,
            behavior: 'smooth',
        });
    }
}

<div class="wrapper">

    <hd-loader *ngIf="loading"></hd-loader>

    <div
        *ngIf="!loading"
         class="content"
        [@fadeIn]
    >
        <h1 class="title">Your discount cards</h1>

        <div class="form">
            <hd-text-input
                example="Start typing to filter . . ."
                [control]="termControl"
                [showClear]="true"
            ></hd-text-input>
        </div>

        <p class="showing-text">
            <ng-container *ngIf="filteredCards.length !== cards.length">
                Showing {{ filteredCards.length }} of {{ cards.length }} establishments
            </ng-container>

            <ng-container *ngIf="filteredCards.length === cards.length">
                {{ filteredCards.length }} establishments
            </ng-container>
        </p>

        <div class="cards">
            <ng-container *ngFor="let card of filteredCards; trackBy: trackByFn">
                <hd-discount-card
                    [card]="card"
                    [date]="date"
                    [time]="time"
                ></hd-discount-card>
            </ng-container>
        </div>
    </div>

</div>

<mc-input-wrapper
    [errorMessage]="errorMessage"
    [invalid]="invalid"
    [inputId]="id"
    [label]="label"
>

    <div class="input-wrapper">
        <input
            class="input"
            [attr.id]="id"
            [formControl]="control"
            [attr.placeholder]="example"
        >

        <a
            *ngIf="showClear && control.value"
            href="#"
            class="clear-button"
            (click)="$event.preventDefault(); onClear()"
        >
            Clear
        </a>
    </div>

</mc-input-wrapper>

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';

import { AreaSelectedGuard } from './guards/area-selected.guard';
import { AreaService } from './services/area.service';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { BlogPageComponent } from './containers/blog-page/blog-page.component';
import { BlogPageModule } from './containers/blog-page/blog-page.module';
import { BlogsPageModule } from './containers/blogs-page/blogs-page.module';
import { BlogsPageComponent } from './containers/blogs-page/blogs-page.component';
import { ChangePasswordPageModule } from './containers/change-password-page/change-password-page.module';
import { EstablishmentPageComponent } from './containers/establishment-page/establishment-page.component';
import { EstablishmentPageModule } from './containers/establishment-page/establishment-page.module';
import { FooterModule } from './components/footer/footer.module';
import { ChangePasswordPageComponent } from './containers/change-password-page/change-password-page.component';
import { ContactUsPageComponent } from './containers/contact-us-page/contact-us-page.component';
import { ContactUsPageModule } from './containers/contact-us-page/contact-us-page.module';
import { DownloadTheAppPageComponent } from './containers/download-the-app-page/download-the-app-page.component';
import { DownloadTheAppPageModule } from './containers/download-the-app-page/download-the-app-page.module';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { HeaderModule } from './components/header/header.module';
import { HomePageComponent } from './containers/home-page/home-page.component';
import { HomePageModule } from './containers/home-page/home-page.module';
import { HowItWorksPageComponent } from './containers/how-it-works-page/how-it-works-page.component';
import { HowItWorksPageModule } from './containers/how-it-works-page/how-it-works-page.module';
import { LoaderModule } from './components/loader/loader.module';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { LoginPageModule } from './containers/login-page/login-page.module';
import { MembershipBarModule } from './components/membership-bar/membership-bar.module';
import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';
import { PageWrapperModule } from './containers/page-wrapper/page-wrapper.module';
import { PageWrapperComponent } from './containers/page-wrapper/page-wrapper.component';
import { PrivacyPolicyPageComponent } from './containers/privacy-policy-page/privacy-policy-page.component';
import { PrivacyPolicyPageModule } from './containers/privacy-policy-page/privacy-policy-page.module';
import { RegistrationPageComponent } from './containers/registration-page/registration-page.component';
import { RegistrationPageModule } from './containers/registration-page/registration-page.module';
import { ResetPasswordPageComponent } from './containers/reset-password-page/reset-password-page.component';
import { ResetPasswordPageModule } from './containers/reset-password-page/reset-password-page.module';
import { SelectAreaModalModule } from './components/select-area-modal/select-area-modal.module';
import { SelectAreaPageComponent } from './containers/select-area-page/select-area-page.component';
import { SelectAreaPageModule } from './containers/select-area-page/select-area-page.module';
import { SocialBarModule } from './components/social-bar/social-bar.module';
import { SubscribePageComponent } from './containers/subscribe-page/subscribe-page.component';
import { SubscribePageModule } from './containers/subscribe-page/subscribe-page.module';
import { SubscribedGuard } from './guards/subscribed.guard';
import { TermsAndConditionsPageModule } from './containers/terms-and-conditions-page/terms-and-conditions-page.module';
import { TermsAndConditionsPageComponent } from './containers/terms-and-conditions-page/terms-and-conditions-page.component';
import { WalksPageComponent } from './containers/walks-page/walks-page.component';
import { WalksPageModule } from './containers/walks-page/walks-page.module';
import { WalkPageComponent } from './containers/walk-page/walk-page.component';
import { WalkPageModule } from './containers/walk-page/walk-page.module';
import { WalletBarModule } from './components/wallet-bar/wallet-bar.module';
import { WalletPageModule } from './containers/wallet-page/wallet-page.module';
import { WalletPageComponent } from './containers/wallet-page/wallet-page.component';
import { WelcomeModalModule } from './components/welcome-modal/welcome-modal.module';
import { YourLocalGuidePageComponent } from './containers/your-local-guide-page/your-local-guide-page.component';
import { YourLocalGuidePageModule } from './containers/your-local-guide-page/your-local-guide-page.module';

import { AppComponent } from './app.component';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot([
            {
                path: '',
                component: PageWrapperComponent,
                children: [
                    {
                        path: '',
                        redirectTo: '/home',
                        pathMatch: 'full',
                    },
                    {
                        path: 'home',
                        canActivate: [AreaSelectedGuard],
                        component: HomePageComponent,
                        data: {
                            showHome: false,
                            showAreaSelector: true,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'establishments/:slug',
                        canActivate: [AreaSelectedGuard],
                        component: EstablishmentPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: false,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'download-the-app',
                        canActivate: [AreaSelectedGuard],
                        component: DownloadTheAppPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: true,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'your-local-guide',
                        canActivate: [AreaSelectedGuard],
                        component: YourLocalGuidePageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: true,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'how-it-works',
                        canActivate: [AreaSelectedGuard],
                        component: HowItWorksPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: true,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'contact-us',
                        canActivate: [AreaSelectedGuard],
                        component: ContactUsPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: true,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'login',
                        canActivate: [AreaSelectedGuard,NotAuthenticatedGuard],
                        component: LoginPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: false,
                            showMembershipBar: false,
                            showWalletBar: false,
                        },
                    },
                    {
                        path: 'reset-password',
                        canActivate: [AreaSelectedGuard],
                        component: ResetPasswordPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: false,
                            showMembershipBar: false,
                            showWalletBar: false,
                        },
                    },
                    {
                        path: 'register',
                        canActivate: [AreaSelectedGuard,NotAuthenticatedGuard],
                        component: RegistrationPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: false,
                            showMembershipBar: false,
                            showWalletBar: false,
                        },
                    },
                    {
                        path: 'subscribe',
                        canActivate: [AreaSelectedGuard,AuthenticatedGuard],
                        component: SubscribePageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: false,
                            showMembershipBar: false,
                            showWalletBar: false,
                        },
                    },
                    {
                        path: 'wallet',
                        canActivate: [AreaSelectedGuard, AuthenticatedGuard, SubscribedGuard],
                        component: WalletPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: true,
                            showMembershipBar: false,
                            showWalletBar: false,
                        },
                    },
                    {
                        path: 'change-password',
                        canActivate: [AreaSelectedGuard, AuthenticatedGuard],
                        component: ChangePasswordPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: false,
                            showMembershipBar: false,
                            showWalletBar: false,
                        },
                    },
                    {
                        path: 'privacy-policy',
                        canActivate: [AreaSelectedGuard],
                        component: PrivacyPolicyPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: true,
                            showMembershipBar: false,
                            showWalletBar: false,
                        },
                    },
                    {
                        path: 'terms-and-conditions',
                        canActivate: [AreaSelectedGuard],
                        component: TermsAndConditionsPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: true,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'walks',
                        canActivate: [AreaSelectedGuard],
                        component: WalksPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: true,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'walks/:slug',
                        canActivate: [AreaSelectedGuard],
                        component: WalkPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: false,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'blogs',
                        canActivate: [AreaSelectedGuard],
                        component: BlogsPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: true,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                    {
                        path: 'blogs/:slug',
                        canActivate: [AreaSelectedGuard],
                        component: BlogPageComponent,
                        data: {
                            showHome: true,
                            showAreaSelector: false,
                            showMembershipBar: true,
                            showWalletBar: true,
                        },
                    },
                ]
            },
            {
                path: 'select-area',
                component: SelectAreaPageComponent,
                data: {
                    showHome: true,
                    showAreaSelector: false,
                    showMembershipBar: true,
                    showWalletBar: true,
                },
            },
        ]),
        ReactiveFormsModule,
        FormsModule,
        AgmOverlays,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCTnJ0a0QnXRCSkpbYus9T41aL2PFsapZ4',
        }),

        BlogPageModule,
        BlogsPageModule,
        ChangePasswordPageModule,
        ContactUsPageModule,
        DownloadTheAppPageModule,
        EstablishmentPageModule,
        FooterModule,
        HeaderModule,
        HomePageModule,
        HowItWorksPageModule,
        YourLocalGuidePageModule,
        LoginPageModule,
        LoaderModule,
        MembershipBarModule,
        PageWrapperModule,
        PrivacyPolicyPageModule,
        RegistrationPageModule,
        ResetPasswordPageModule,
        SelectAreaModalModule,
        SelectAreaPageModule,
        SocialBarModule,
        SubscribePageModule,
        TermsAndConditionsPageModule,
        WalkPageModule,
        WalksPageModule,
        WalletPageModule,
        WalletBarModule,
        WelcomeModalModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (areaService: AreaService) => () => {
                return areaService.getAll()
                    .toPromise();
            },
            deps: [ AreaService ],
            multi: true,
        },
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {}

<div class="hide-scroll">
    <div class="scroll" #scroll>
        <div
            class="cards"
            [class.cards--off-screen]="offScreen"
            #cards
        >
            <ng-content></ng-content>
        </div>
    </div>
</div>

<a
    class="arrow arrow--left"
    [class.arrow--visible]="showLeftArrow"
    (click)="onScrollLeft()"
></a>

<a
    class="arrow arrow--right"
    [class.arrow--visible]="showRightArrow"
    (click)="onScrollRight()"
></a>

import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AccountService } from '../../services/account.service';
import { Area } from '../../models/area.model';

@Component({
    selector: 'hd-header',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.scss' ],
})
export class HeaderComponent implements OnInit {

    @Input() area: Area;
    @HostBinding('class.show-area-selector') @Input() showAreaSelector: boolean;
    @Input() showHome: boolean;

    @Output() manageSubscription = new EventEmitter();
    @Output() logout = new EventEmitter();
    @Output() viewAreaSelector = new EventEmitter();

    menuVisible = false;

    user$: Observable<any>;

    get authenticated () { return AccountService.isAuthenticated(); }
    get subscriptionActive () { return AccountService.stripeSubscriptionActive(); }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.user$ = AccountService.user$;
    }

    ngOnInit() {
        this.router.events
            .pipe(filter(e => e instanceof NavigationStart))
            .subscribe(() => {
                this.closeMenu();
            });
    }

    openMenu() {
        this.menuVisible = true;

        this.fixBody();
    }

    closeMenu() {
        this.menuVisible = false;

        this.unfixBody();
    }

    fixBody() {
        const scrollY = window.scrollY;

        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollY}px`;
    }

    unfixBody() {
        const scrollY = document.body.style.top;

        document.body.style.position = '';
        document.body.style.top = '';

        window.scrollTo(0, -parseInt(scrollY, 10));
    }

    onViewAreaSelector() {
        this.closeMenu();

        this.viewAreaSelector.emit();
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { Area } from '../../models/area.model';
import { AreaService } from '../../services/area.service';

@Component({
    selector: 'hd-select-area-page',
    templateUrl: './select-area-page.component.html',
    styleUrls: [ './select-area-page.component.scss' ],
})
export class SelectAreaPageComponent implements OnInit {

    areas: Area[];

    redirect = this.route.snapshot.queryParams.redirect;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private areaService: AreaService,
    ) {}

    ngOnInit() {
        (document.querySelector('[name="theme-color"]') as any).content = '#0178ff';

        this.areaService.getAll()
            .subscribe(areas => {
                this.areas = areas;
            });
    }

    onChoose(area: Area) {
        AreaService.updateSelected(area);

        this.router.navigate([ this.redirect || '/' ]);
    }
}

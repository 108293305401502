<div [@fadeIn]>
    <h1 class="title">Change Password</h1>

    <p class="text">
       You can use the form below to change your password if you already know it
    </p>

    <form (submit)="onChange(form)">

        <hd-message-block
            *ngIf="changed"
            severity="success"
        >
            Your password has been successfully changed
        </hd-message-block>

        <hd-message-block
            *ngIf="error"
            severity="error"
        >
            <ng-container *ngIf="incorrectPassword">
                Sorry, the old password appears to be incorrect
            </ng-container>

            <ng-container *ngIf="!incorrectPassword">
                Sorry, there was an issue changing your password. Please try again later
            </ng-container>
        </hd-message-block>

        <hd-form-group>
            <hd-password-input
                errorMessage="Please enter your old password"
                label="Old Password"
                name="old-password"
                [control]="oldPasswordControl"
            ></hd-password-input>
        </hd-form-group>

        <hd-form-group>
            <hd-password-input
                errorMessage="Please enter a valid password"
                label="New Password"
                name="new-password"
                [control]="newPasswordControl"
                [validate]="true"
                [showRules]="true"
            ></hd-password-input>
        </hd-form-group>

        <button
            class="button"
            [disabled]="changing"
        >

            <ng-container *ngIf="!changing">
                Change password
            </ng-container>

            <hd-loader
                *ngIf="changing"
                [small]="true"
                [flush]="true"
            ></hd-loader>

        </button>
    </form>
</div>
<div class="dialog">

    <section class="section section--large">
        <img
            class="logo"
            src="/assets/images/logo-full.png"
        >

        <p class="text">
            Unearth those independent businesses we call hidden gems, enjoy hundreds of
            miles of circular walking routes and get exclusive discounts at all the
            businesses featured on our app.
        </p>

        <p class="text">
            Each area is managed by a local guide who is passionate about sharing the best of their
            area with you.
        </p>
    </section>

    <section class="section section--last">
        <h2 class="heading">Choose your location</h2>

        <div class="areas">
            <a
                *ngFor="let area of areas"
                href="#"
                class="area"
                [style.backgroundColor]="area.primaryColour"
                (click)="$event.preventDefault(); onChoose(area)"
            >
                <img
                    class="area__logo"
                    [src]="area.logo"
                >

                {{ area.name }}
            </a>
        </div>
    </section>

    <a
        href="#"
        class="close"
        (click)="$event.preventDefault(); close.emit()"
    ></a>
</div>
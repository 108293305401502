import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { SocialBlockModule } from '../social-block/social-block.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SocialBlockModule,
    ],
    declarations: [
        HeaderComponent,
    ],
    exports: [
        HeaderComponent,
    ],
})
export class HeaderModule {}

<div
    class="placeholder-image"
    [class.placeholder-image--hidden]="imageLoaded"
></div>

<img
    class="image"
    [attr.src]="image"
    (load)="onLoadImage()"
>

<p
    *ngIf="featureText"
    class="feature-text"
>
    {{ featureText }}
</p>

<h1
    *ngIf="title"
    class="title"
>
    {{ title }}
</h1>

<p
    *ngIf="description"
    class="description"
>
    {{ description }}
</p>

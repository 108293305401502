import { Component, Input } from '@angular/core';

import { InputComponent } from '../input-component.class';

@Component({
    selector: 'hd-textarea-input',
    templateUrl: './textarea-input.component.html',
    styleUrls: [ './textarea-input.component.scss' ],
})
export class TextareaInputComponent extends InputComponent<string> {}

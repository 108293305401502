import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot
} from "@angular/router";
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AreaService } from "../services/area.service";

@Injectable({
    providedIn: 'root',
})
export class AreaSelectedGuard implements CanActivate {

    constructor(
        private router: Router,
        private areaService: AreaService,
    ) {}

    canActivate(_route: ActivatedRouteSnapshot, { url }: RouterStateSnapshot) {
        return this.areaService.getAll()
          .pipe(map(() => {
              if (!AreaService.selected) {
                  this.router.navigate([ '/select-area' ], {
                      queryParams: {
                          redirect: url,
                      },
                  });

                  return false;
              }

              return true;
          }));
    }
}

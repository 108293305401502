import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Fulfilment } from '../../models/fulfilment.model';

@Component({
    selector: 'hd-establishment-filters',
    templateUrl: './establishment-filters.component.html',
    styleUrls: [ './establishment-filters.component.scss' ],
})
export class EstablishmentFiltersComponent {

    @Input() mapView: boolean;

    @Output() filter = new EventEmitter<{ fulfilment: Fulfilment[], open: string[] }>();
    @Output() toggleView = new EventEmitter();

    fulfilmentListOpen = false;
    openListOpen = false;

    form: FormGroup;

    fulfilmentOptions = [
        {
            label: 'Delivery',
            value: Fulfilment.Deliver,
        },
        {
            label: 'Takeaway',
            value: Fulfilment.Takeaway,
        },
        {
            label: 'Visit',
            value: Fulfilment.Visit,
        },
    ];
    openOptions = [
        {
            label: new Date().getDay() === 1 ? 'Today' : 'Monday',
            value: 'monday',
        },
        {
            label: new Date().getDay() === 2 ? 'Today' : 'Tuesday',
            value: 'tuesday',
        },
        {
            label: new Date().getDay() === 3 ? 'Today' : 'Wednesday',
            value: 'wednesday',
        },
        {
            label: new Date().getDay() === 4 ? 'Today' : 'Thursday',
            value: 'thursday',
        },
        {
            label: new Date().getDay() === 5 ? 'Today' : 'Friday',
            value: 'friday',
        },
        {
            label: new Date().getDay() === 6 ? 'Today' : 'Saturday',
            value: 'saturday',
        },
        {
            label: new Date().getDay() === 0 ? 'Today' : 'Sunday',
            value: 'sunday',
        },
    ];

    get fulfilmentControl() { return this.form.get('fulfilment'); }
    get openControl() { return this.form.get('open'); }

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            fulfilment: [[]],
            open: [[]],
        })
    }

    onToggle(control, value: any) {
        if (control.value.includes(value)) {
            control.setValue(control.value.filter(a => a !== value));
        } else {
            control.setValue([ ...control.value, value ]);
        }

        this.filter.emit(this.form.value);
    }

}

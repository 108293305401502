<div [@fadeIn]>

    <h1 class="sub-title">
        <ng-container *ngIf="user">
            Welcome to be local {{ user.given_name }}
        </ng-container>

        <ng-container *ngIf="!user">
            How it works
        </ng-container>
    </h1>

    <p *ngIf="!user" class="text">
        Anyone can become a be local member for just £1.99 a month, for that you’ll have access to
        discounts at over 100 independents in our area. It takes just two minutes to sign up and you can
        cancel at any time.
    </p>

    <h2 class="sub-title">Using the discounts</h2>

    <p class="text">
        {{ user ? 'Now you’ve subscribed you have' : 'Once you’ve subscribed you’ll have' }} access to a digital discount wallet. In here you’ll find a discount
        card for each independent; you can search to find the card you need and then save it to your favourites.
    </p>

    <p class="text">
        Using your membership is really simple. Show the independent their card to redeem the discount. If you're
        ordering online use the online code that's displayed on the discount card. Please remember to let the
        independent know you’re a be local member as soon as you can, this will help them prepare the bill
        accordingly.
    </p>

    <p class="text">
        We suggest downloading the be local app as the most convenient way to use your membership.
    </p>

    <h2 class="sub-title">Where does your subscription go?</h2>

    <p class="text">
        The monthly subscription fee is spent on managing and updating the app and website and promoting all
        the great things that the independents do. It also goes towards helping them with their marketing; that’s
        things like photography, graphic design and social media consultancy. We don’t charge the independents to
        be part of be local or for the support we offer them.
    </p>

    <a *ngIf="!user" routerLink="/register" class="button">Join now</a>

    <a *ngIf="user" routerLink="/wallet" class="button">View your wallet</a>

</div>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { trigger } from '@angular/animations';

import { AccountService } from '../../services/account.service';
import { Area } from '../../models/area.model';
import { fadeInAnimation } from '../../animations/fade-in';
import { AreaService } from '../../services/area.service';

@Component({
    selector: 'hd-registration-page',
    templateUrl: './registration-page.component.html',
    styleUrls: [ './registration-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class RegistrationPageComponent implements OnInit {

    form = new FormGroup({
        firstName: new FormControl('', { validators: [ Validators.required ], updateOn: 'blur' }),
        lastName: new FormControl('', { validators: [ Validators.required ], updateOn: 'blur' }),
        email: new FormControl('', { validators: [ Validators.required ], updateOn: 'blur' }),
        location: new FormControl('', { validators: [ Validators.required ], updateOn: 'blur' }),
        area: new FormControl(AreaService.selected, { validators: [ Validators.required ], updateOn: 'blur' }),
        password: new FormControl(''),
        terms: new FormControl(false, { validators: [ control => !control.value ? { required: true } : null ] }),
        comms: new FormControl(false),
    });

    areasLoading = false;
    areasLoadingError = false;
    areaOptions: { label: string; value: Area }[];
    creatingAccount = false;
    error = false;

    get firstNameControl() { return this.form.get('firstName') as FormControl; }
    get lastNameControl() { return this.form.get('lastName') as FormControl; }
    get emailControl() { return this.form.get('email') as FormControl; }
    get locationControl() { return this.form.get('location') as FormControl; }
    get areaControl() { return this.form.get('area') as FormControl; }
    get passwordControl() { return this.form.get('password') as FormControl; }
    get termsControl() { return this.form.get('terms') as FormControl; }
    get commsControl() { return this.form.get('comms') as FormControl; }

    constructor(
        private router: Router,
        private accountService: AccountService,
        private areaService: AreaService,
    ) {}

    ngOnInit() {
        this.areasLoadingError = false;
        this.areasLoading = true;

        this.areaService.getAll()
            .subscribe({
                next: areas => {
                    this.areasLoading = false;
                    this.areasLoadingError = false;
                    this.areaOptions = areas.map(area => ({
                        label: area.name,
                        value: area,
                    }));
                },
                error: () => {
                    this.areasLoading = false;
                    this.areasLoadingError = true;
                }
            });
    }

    onCreateCustomer(form: FormGroup) {
        if (form.invalid) {
            (document.activeElement as any).blur();

            this.firstNameControl.markAsTouched();
            this.lastNameControl.markAsTouched();
            this.emailControl.markAsTouched();
            this.areaControl.markAsTouched();
            this.locationControl.markAsTouched();
            this.passwordControl.markAsTouched();
            this.termsControl.markAsTouched();
            this.commsControl.markAsTouched();

            return;
        }

        this.error = false;
        this.creatingAccount = true;

        this.accountService.create(form.value)
            .subscribe({
                next: () => {
                    this.creatingAccount = false;

                    this.router.navigate([ '/subscribe' ]);
                },
                error: error => {
                    this.creatingAccount = false;

                    if (error.code === 'UsernameExistsException') {
                        return this.router.navigate([ '/login' ], {
                            queryParams: { accountAlreadyExists: true },
                        });
                    }

                    this.error = true;
                },
            });
    }

    areaComparator(a: Area, b: Area) {
        return a?._id === b?._id;
    }
}

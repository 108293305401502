import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hd-loader',
    templateUrl: './loader.component.html',
    styleUrls: [ './loader.component.scss' ],
})
export class LoaderComponent {

    @HostBinding('class.overlay') @Input() overlay = false;
    @HostBinding('class.flush') @Input() flush = false;
    @HostBinding('class.small') @Input() small = false;
    @HostBinding('class.light') @Input() light = false;

}

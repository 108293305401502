import { Component } from '@angular/core';

import { AccountService } from "../../services/account.service";

@Component({
    selector: 'hd-how-it-works-page',
    templateUrl: './how-it-works-page.component.html',
    styleUrls: [ './how-it-works-page.component.scss' ],
})
export class HowItWorksPageComponent {

    user$ = AccountService.user$;

}

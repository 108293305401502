import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CarouselModule } from '../../components/carousel/carousel.module';
import { EstablishmentBlockModule } from '../../components/establishment-block/establishment-block.module';

import { HomePageComponent } from './home-page.component';
import { ContainerModule } from '../../components/container/container.module';
import { LoaderModule } from '../../components/loader/loader.module';
import { FulfilmentFilterModule } from '../../components/fulfilment-filter/fulfilment-filter.module';
import { HeaderModule } from '../../components/header/header.module';
import { NoResultsModule } from '../../components/no-results/no-results.module';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import {SocialSectionModule} from "../../components/social-section/social-section.module";

@NgModule({
    imports: [
        CommonModule,

        CarouselModule,
        EstablishmentBlockModule,
        ContainerModule,
        LoaderModule,
        FulfilmentFilterModule,
        HeaderModule,
        NoResultsModule,
        AgmCoreModule,
        AgmOverlays,
        SocialSectionModule,
    ],
    declarations: [
        HomePageComponent,
    ],
    exports: [
        HomePageComponent,
    ],
})
export class HomePageModule {}

<mc-input-wrapper
    [errorMessage]="errorMessage"
    [invalid]="invalid"
    [inputId]="id"
    [label]="label"
>

    <input
        class="input"
        [attr.id]="id"
        [email]="true"
        [formControl]="control"
    >

</mc-input-wrapper>

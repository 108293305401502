import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger } from '@angular/animations';

import { fadeInAnimation } from '../../animations/fade-in';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'hd-reset-password-page',
    templateUrl: './reset-password-page.component.html',
    styleUrls: [ './reset-password-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class ResetPasswordPageComponent {

    emailToBeReset: string;
    action: 'REQUEST' | 'RESET' = 'REQUEST';
    error = false;
    invalidCode = false;

    requestForm = new FormGroup({
        email: new FormControl('', [ Validators.required ]),
    }, { updateOn: 'blur' })

    resetForm = new FormGroup({
        verificationCode: new FormControl('', { validators: [ Validators.required ], updateOn: 'blur' }),
        newPassword: new FormControl(''),
    });

    get requestEmailControl() { return this.requestForm.get('email'); }
    get resetVerificationCodeControl() { return this.resetForm.get('verificationCode'); }
    get resetNewPasswordControl() { return this.resetForm.get('newPassword'); }

    resetting = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
    ) {}

    onRequest(form: FormGroup) {
        if (form.invalid) {
            this.requestEmailControl.markAsTouched();

            return;
        }

        this.emailToBeReset = form.value.email;
        this.resetting = true;
        this.error = false;

        this.accountService.resetPasswordRequest(form.value.email)
            .subscribe({
                next: () => {
                    this.resetting = false;
                    this.action = 'RESET';
                },
                error: () => {
                    this.resetting = false;
                    this.error = true;
                },
            });
    }

    onReset(form: FormGroup) {
        if (form.invalid) {
            this.resetVerificationCodeControl.markAsTouched();
            this.resetNewPasswordControl.markAsTouched();

            return;
        }

        this.resetting = true;
        this.error = false;
        this.invalidCode = false;

        this.accountService.resetPassword(this.emailToBeReset, form.value.verificationCode, form.value.newPassword)
            .subscribe({
                next: () => {
                    this.router.navigate([ '/login' ], {
                        queryParams: { passwordReset: true },
                    });
                },
                error: ({ error: { code } }) => {
                    this.resetting = false;
                    this.error = true;
                    this.invalidCode = code === 'CodeMismatchException';
                },
            });
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContainerModule } from '../../components/container/container.module';

import { WalletPageComponent } from './wallet-page.component';
import { SocialIconsModule } from '../../components/social-icons/social-icons.module';
import { FormModule } from '../../components/form/form.module';
import { LoaderModule } from '../../components/loader/loader.module';
import { DiscountCardModule } from '../../components/discount-card/discount-card.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ContainerModule,
        SocialIconsModule,
        FormModule,
        LoaderModule,
        DiscountCardModule,
    ],
    declarations: [
        WalletPageComponent,
    ],
    exports: [
        WalletPageComponent,
    ],
})
export class WalletPageModule {}

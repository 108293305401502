<a
    href="https://www.instagram.com/homedinesolihull"
    target="_blank"
    class="social-link"
>
    <img
        src="/assets/images/logos/instagram.png"
        class="social-icon"
    >
</a>

<a
    href="https://www.facebook.com/homedinesolihull-113661703695253"
    target="_blank"
    class="social-link"
>
    <img
        src="/assets/images/logos/facebook.png"
        class="social-icon"
    >
</a>

<iframe
    src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fhomedinesolihull-113661703695253&width=112&layout=button&action=like&size=large&share=true&height=28&appId=295415281495219"
    width="130"
    height="28"
    style="border:none;overflow:hidden"
    scrolling="no"
    frameborder="0"
    allowTransparency="true"
    allow="encrypted-media"
></iframe>

import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { delay, filter, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { trigger } from '@angular/animations';

import { AreaService } from '../../services/area.service';
import { Area } from '../../models/area.model';
import { AccountService } from '../../services/account.service';
import { fadeInAnimation } from '../../animations/fade-in';
import { slideFromBottomAnimation } from '../../animations/slide-from-bottom';

@Component({
    selector: 'hd-page-wrapper',
    templateUrl: './page-wrapper.component.html',
    styleUrls: [ './page-wrapper.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
        trigger('slideInFromBottom', slideFromBottomAnimation),
    ]
})
export class PageWrapperComponent implements OnInit {

    area: Area;
    selectAreaModalVisible = false;
    authenticated$ = AccountService.authenticated$;

    loadingPage$ = this.router.events
        .pipe(
            filter(event =>
                event instanceof NavigationStart ||
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError,
            ),
            map(event => event instanceof NavigationStart),
        );

    showAreaSelector$: Observable<boolean>;
    showHome$: Observable<boolean>;
    showMembershipBar$: Observable<boolean>;
    showWalletBar$: Observable<boolean>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
    ) {}

    ngOnInit() {
        const route$ = merge(
            of(this.route),
            this.router.events
                .pipe(
                    filter(e => e instanceof NavigationEnd),
                    map(() => this.route),
                )
        )
            .pipe(map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }

                return route;
            }));

        this.showAreaSelector$ = route$.pipe(map(({ snapshot: { data: { showAreaSelector } } }) => showAreaSelector));
        this.showHome$ = route$.pipe(map(({ snapshot: { data: { showHome } } }) => showHome));
        this.showWalletBar$ = combineLatest([
            this.authenticated$,
            route$.pipe(map(({ snapshot: { data: { showWalletBar } } }) => showWalletBar)),
        ])
            .pipe(
                map(([ authenticated, showWalletBar ]) => authenticated && showWalletBar),
                startWith(false),
                pairwise(),
                switchMap(([ walletBarVisible, showWalletBar ]) => {
                    if (!walletBarVisible) {
                        return of(showWalletBar).pipe(delay(2000));
                    }

                    return of(showWalletBar);
                })
            );
        this.showMembershipBar$ = combineLatest([
            this.authenticated$,
            route$.pipe(map(({ snapshot: { data, queryParams } }) => {
                return data.showMembershipBar && !queryParams.hasOwnProperty('hideMembershipBar');
            }))
        ])
            .pipe(
                map(([ authenticated, showMembershipBar ]) => !authenticated && showMembershipBar),
                startWith(false),
                pairwise(),
                switchMap(([ membershipBarVisible, showMembershipBar ]) => {
                    if (!membershipBarVisible) {
                        return of(showMembershipBar).pipe(delay(2000));
                    }

                    return of(showMembershipBar);
                })
            );

        const scrollLevels = {};
        let lastId = 0;
        let restoredId: number;

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                scrollLevels[lastId] = window.scrollY;
                lastId = event.id;
                restoredId = event.restoredState ? event.restoredState.navigationId : undefined;
            }

            if (event instanceof NavigationEnd) {
                if (restoredId) {
                    setTimeout(() => window.scrollTo(0, scrollLevels[restoredId] || 0))
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });

        AreaService.changed$
            .pipe(
                startWith(AreaService.selected),
                filter(Boolean),
            )
            .subscribe((area: Area) => {
                this.area = area;

                document.documentElement.style.setProperty('--primary-colour', hexToRgb(area.primaryColour));
                document.documentElement.style.setProperty('--secondary-colour', hexToRgb(area.secondaryColour));

                (document.querySelector('[name="theme-color"]') as any).content = area.primaryColour;
            });
    }

    onManageSubscription() {
        this.accountService.createCustomerPortalSession()
            .subscribe(url => {
                window.location.href = url;
            })
    }

    onLogout() {
        this.accountService.logout()
            .subscribe(() => {
                this.router.navigate([ '/login' ], {
                    queryParams: {
                        loggedOut: true,
                    },
                });
            })
    }

    onToggleSelectAreaModalVisibility() {
        this.selectAreaModalVisible = !this.selectAreaModalVisible;
    }
}

function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`;
}
<div
    class="wrapper"
    [@fadeIn]
>

    <h1 class="title">Login</h1>

    <form (submit)="onLogin(form)">

        <hd-message-block
            *ngIf="loggedOut"
            severity="success"
        >
            You have been logged out
        </hd-message-block>

        <hd-message-block
            *ngIf="passwordReset"
            severity="success"
        >
            Your password has been successfully changed
        </hd-message-block>

        <hd-message-block
            *ngIf="accountAlreadyExists"
        >
            You've already got an account with us. You can log into your account below
        </hd-message-block>

        <hd-message-block
            *ngIf="error"
            severity="error"
        >
            The provided credentials are not correct
        </hd-message-block>

        <hd-form-group>
            <hd-email-input
                errorMessage="Please enter a valid email"
                label="Email"
                name="email"
                [control]="emailControl"
            ></hd-email-input>
        </hd-form-group>

        <hd-form-group>
            <hd-password-input
                errorMessage="Please enter a password"
                label="Password"
                name="password"
                [control]="passwordControl"
                [showRules]="false"
                [validate]="false"
            ></hd-password-input>

            <a
                class="forgotten-password-link"
                routerLink="/reset-password"
            >
                Forgotten password?
            </a>
        </hd-form-group>

        <button
            class="button"
            [disabled]="authenticating"
        >

            <ng-container *ngIf="!authenticating">
                Login
            </ng-container>

            <hd-loader
                *ngIf="authenticating"
                [small]="true"
                [flush]="true"
            ></hd-loader>

        </button>
    </form>

    <a
        class="button button--alternative"
        routerLink="/register"
    >
        Sign up
    </a>

</div>

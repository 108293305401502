<div [@fadeIn]>

    <h1 class="title">Terms & Conditions</h1>

    <p class="text">Last updated: 15/05/2022</p>

    <p class="text">
        These Terms & Conditions constitute a legally binding agreement made between you, whether
        personally or on behalf of an entity (“you”) and home-dine club ltd ("be local”, “we”, “us”, or “our”),
        concerning your access to and use of the <a href="https://www.be-local.uk">https://www.be-local.uk</a>
        website as well as any other media form, media channel, mobile website or mobile application related,
        linked, or otherwise connected thereto (collectively, the “Site”). You agree that by becoming a be
        local member, you have read, understood, and agreed to be bound by all of the Terms & Conditions.
    </p>

    <p class="text">
        We reserve the right, to make changes or modifications to these Terms & Conditions at any time and
        for any reason. We will alert you about any changes by updating the “Last updated” date of these
        Terms & Conditions. It is your responsibility to periodically review these Terms & Conditions to
        stay informed of updates. You will be subject to, and will be deemed to have been made aware of
        and to have accepted, the changes in any revised Terms & Conditions by your continued use of the
        Site after the date such revised Terms & Conditions are posted.
    </p>

    <p class="text">
        When we refer to independents/independent/be local independent we are referring to the businesses
        that are listed on <a href="https://www.be-local.uk">https://www.be-local.uk</a>
    </p>

    <ol>
        <li class="text">
            <strong>How to join be local</strong>

            <p>
                To join be local simply register by clicking join now/register and provide us with your
                name(s) and contact details, you will also be asked to create a password. You will then
                be requested for your payment card details which will be taken via Stripe in order to
                fulfil the subscription requirements. Your membership will start as soon as you join,
                when you will be able to unlock all the digital discount cards for each independent and
                enjoy use of your membership straight away. When you revisit the website or app it may
                prompt you to log in again, please use your email address and the password you set upon
                registration.
            </p>
        </li>

        <li class="text">
            <strong>How to use your membership</strong>

            <p>Using your membership is straightforward:</p>

            <ul>
                <li>Simply go to your Discount Wallet and search for or find the digital discount card for your chosen independent</li>
                <li>Click ‘use discount card’ when you’re ready to claim your discount.</li>
                <li>If this is at an independent’s establishment please show them your digital discount card.</li>
                <li>If this is for an online purchase please use the online code in the promotional code box on their website.</li>
                <li>There is no limit on how many times a day you can access your discount wallet or use a digital discount card.</li>
                <li>Offer details for each independent can vary so please check the individual discount cards.</li>
            </ul>

        </li>

        <li class="text">
            <strong>How the membership subscription works</strong>

            <p>
                be local membership is based on a monthly subscription which auto-renews unless cancelled.
                This subscription can only be paid for via direct debit through our registration process
                on our website. There is a minimum term of one month for all new registrations; thereafter
                the membership will continue and payment will be taken monthly until the subscription
                is stopped by you.
            </p>

            <p>
                In order to process your payment we use Stripe, a third party payment processor. Your
                payment will be processed by Stripe who collect, use and process your information in
                accordance with their privacy policy.
            </p>
        </li>

        <li class="text">
            <strong>How to cancel your membership</strong>

            <p>
                Stopping your subscription via Stripe on our website is simple. Visit Manage my
                Subscription from there you can cancel your subscription. As long as this is done
                48 hours before your next monthly payment is due it will be cancelled at the end
                of your billing period.

            </p>

            <p>
                If Stripe is unable to take your monthly subscription you will still be able to
                log-in to update your payment card details. At this point your discount wallet
                won’t be available to you until payment is successful.
            </p>
        </li>

        <li class="text">
            <strong>What you get from being a be local member</strong>

            <p>
                You get access to a discount wallet via your account once you’ve registered at
                <a href="https://www.be-local.uk">https://www.be-local.uk</a> This contains a digital discount
                card for each independent on the website that you can use to save you money on meals and drinks
                out, takeaways, gifts and groceries at some of the best independents in the area. You will have
                access to all the areas on be local.
            </p>

            <p>
                We’ll also send emails from time to time to keep you updated with new independents and new offers.
                Another way to get the best out of your membership is to follow us on Facebook and Instagram.
            </p>
        </li>

        <li class="text">
            <strong>Ensuring successful use of the membership</strong>

            <p>
                There are additional terms and conditions on each of the independents digital discount cards,
                please check these terms to make sure you are eligible for the discount. There is a ticking
                time clock on each of the digital discount cards to ensure they can’t be shared. The digital
                discount card must be with and used by the specified and registered owner only.
            </p>
        </li>

        <li class="text">
            <strong>Subscription cost</strong>

            <p>
                The monthly subscription cost is £1.99 a month. This cost will be reviewed annually. We have
                the right to change this subscription giving 28 days notice by updating our terms and
                conditions and notifying our members via email.
            </p>
        </li>

        <li class="text">
            <strong>The rights of the independents</strong>

            <p>
                The independents offering discounts reserve the right to decline the be local membership
                on a discretionary basis if they doubt its validity. be local holds no liability for
                discounts that are refused, independents changing discounts or joining/leaving be local.
            </p>

            <p>
                The independents listed on be local website are able to withdraw from be local and
                change their agreed discount at any time. Additionally, new independents can join
                be local membership club. Members will not be notified of changes to independents
                or the discounts they are offering.
            </p>

            <p>
                We reserve the right to choose which independents are part of be local membership club.
            </p>
        </li>

        <li class="text">
            <strong>Accuracy of information on our website</strong>

            <p>
                We will try to keep our website as up to date as possible but, in the case, where it is
                not up to date we are not liable for misinformation. We do not guarantee the accuracy of
                the content that we obtain from our independents.
            </p>
        </li>

        <li class="text">
            <strong>Your experience at a be local independent</strong>

            <p>
                We are not liable for any experiences at an independent that is part of be local (good or bad).
                We hold no liability for anything that happens at or with a be local independent. We will not
                get involved with any relations between a be local member and a be local independent.
            </p>
        </li>
    </ol>
</div>

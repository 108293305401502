<div [@fadeIn]>

    <h1 class="title">Contact Us</h1>

    <p class="text">
        {{ 'We\'d love to hear from you. You can contact us ' + (area.facebookUrl || area.instagramUrl ? 'through social media, or ' : '') + 'using the form below.' }}
    </p>

    <hd-social-icons
        [facebookUrl]="area.facebookUrl"
        [instagramUrl]="area.instagramUrl"
    ></hd-social-icons>

    <hd-message-block
        *ngIf="sent"
        severity="success"
    >
        Thanks, we'll get in touch with you shortly!
    </hd-message-block>

    <hd-message-block
        *ngIf="error"
        severity="error"
    >
        There was an issue sending your enquiry!
    </hd-message-block>

    <form (submit)="onSubmit(form)">

        <hd-form-group>
            <hd-text-input
                errorMessage="Please enter your name"
                name="name"
                label="Name"
                [control]="nameControl"
            ></hd-text-input>
        </hd-form-group>

        <hd-form-group>
            <hd-email-input
                errorMessage="Please enter a valid email"
                name="email"
                label="Email"
                [control]="emailControl"
            ></hd-email-input>
        </hd-form-group>

        <hd-form-group>
            <hd-textarea-input
                errorMessage="Please enter a message"
                name="message"
                label="Message"
                [control]="messageControl"
            ></hd-textarea-input>
        </hd-form-group>

        <button
            [disabled]="sending"
            class="button"
        >
            <ng-container *ngIf="!sending">
                Send
            </ng-container>

            <hd-loader
                *ngIf="sending"
                [small]="true"
                [flush]="true"
            ></hd-loader>

        </button>
    </form>

</div>
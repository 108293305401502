import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContainerModule } from '../../components/container/container.module';

import { BlogPageComponent } from './blog-page.component';
import { LoaderModule } from '../../components/loader/loader.module';
import { SplashModule } from '../../components/splash/splash.module';

@NgModule({
    imports: [
        CommonModule,

        ContainerModule,
        LoaderModule,
        SplashModule,
    ],
    declarations: [
        BlogPageComponent,
    ],
    exports: [
        BlogPageComponent,
    ],
})
export class BlogPageModule {}

<hd-header
    [area]="area"
    [showAreaSelector]="showAreaSelector$ | async"
    [showHome]="showHome$ | async"
    (manageSubscription)="onManageSubscription()"
    (viewAreaSelector)="onToggleSelectAreaModalVisibility()"
    (logout)="onLogout()"
></hd-header>

<router-outlet></router-outlet>

<hd-loader
    *ngIf="loadingPage$ | async"
    [overlay]="true"
></hd-loader>

<hd-footer></hd-footer>

<hd-membership-bar
    *ngIf="showMembershipBar$ | async"
    [logo]="area?.logo"
    [@slideInFromBottom]
></hd-membership-bar>

<hd-wallet-bar
    *ngIf="showWalletBar$ | async"
    [@slideInFromBottom]
></hd-wallet-bar>

<hd-select-area-modal
    [visible]="selectAreaModalVisible"
    (close)="onToggleSelectAreaModalVisibility()"
></hd-select-area-modal>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AccountService } from './account.service';
import { Area } from '../models/area.model';
import { DiscountCard } from '../models/discount-card.model';

@Injectable({
    providedIn: 'root',
})
export class WalletService {

    constructor(private http: HttpClient) {}

    get(area: Area) {
        return this.http.get<DiscountCard[]>('https://api.be-local.uk/wallet', {
            headers: {
                authorization: `Bearer ${AccountService.getToken()}`,
            },
            params: {
                areaId: area._id,
            },
        });
    }

}

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { timer } from 'rxjs';
import { trigger } from '@angular/animations';

import { AreaService } from '../../services/area.service';
import { BaseComponent } from '../../components/base-component.class';
import { DiscountCard } from '../../models/discount-card.model';
import { fadeInAnimation } from '../../animations/fade-in';
import { WalletService } from '../../services/wallet.service';

@Component({
    selector: 'hd-wallet-page',
    templateUrl: './wallet-page.component.html',
    styleUrls: [ './wallet-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class WalletPageComponent extends BaseComponent implements OnInit {

    filteredCards: DiscountCard[];
    cards: DiscountCard[];

    loading = false;
    termControl = new FormControl(this.route.snapshot.queryParams.term);

    date: string;
    time: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private walletService: WalletService
    ) {
        super();
    }

    ngOnInit() {
        AreaService.changed$
            .pipe(
                startWith(AreaService.selected),
                takeUntil(this.destroyed$),
            )
            .subscribe(() => this.loadData());

        this.route.queryParams
            .subscribe(({ term }) => {
                this.filterCards(term);
            })

        this.termControl.valueChanges
            .subscribe(term => {
                this.router.navigate([ 'wallet' ], {
                    queryParams: {
                        term,
                    }
                })
            });

       timer(0, 1000)
            .pipe(
                map(() => new Date()),
                takeUntil(this.destroyed$),
            )
           .subscribe(now => {
               this.date = now.toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: 'numeric' });
               this.time = now.toLocaleTimeString(undefined, { hour12: true });
           });
    }

    loadData() {
        this.loading = true;

        this.walletService.get(AreaService.selected)
            .subscribe(cards => {
                this.loading = false;
                this.cards = cards;
                this.termControl.reset();

                this.filterCards(this.termControl.value);
            });
    }

    filterCards(term: string) {
        if (!this.cards) {
            return;
        }

        if (!term) {
            this.filteredCards = this.cards;

            return;
        }

        this.filteredCards = this.cards.filter(({ name }) => name.toLowerCase().includes(term.toLowerCase()));
    }

    trackByFn(index, { id }) {
        return id;
    }
}

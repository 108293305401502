<div class="dialog">
    <h1 class="heading">

        Download our

        <br>

        <span class="heading__small">Multi-Award Winning app</span>

    </h1>

    <img src="/assets/images/app-images.png" class="image">

    <p class="text">
        Featuring discounts up to 25% off from over 100 independents plus lots of circular walks.
    </p>

    <p class="text">
        It's your handy go-to guide for discovering the very best of Solihull
    </p>

    <div class="buttons">
        <a href="https://apps.apple.com/gb/app/home-dine/id1550837261" class="button" target="_blank">
            <img src="/assets/images/download-on-app-store.svg" class="button__image">
        </a>

        <a href="https://play.google.com/store/apps/details?id=uk.co.homeDine.homeDine&hl=en&gl=gb" class="button" target="_blank">
            <img src="/assets/images/get-it-on-google-play.png" class="button__image">
        </a>
    </div>

    <a href="#" class="close" (click)="$event.preventDefault(); onClose()"></a>
</div>

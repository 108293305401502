import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EstablishmentFiltersComponent } from './establishment-filters.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        EstablishmentFiltersComponent,
    ],
    exports: [
        EstablishmentFiltersComponent,
    ],
})
export class FulfilmentFilterModule {}

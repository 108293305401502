import { Component, Input } from '@angular/core';

@Component({
    selector: 'hd-social-icons',
    templateUrl: './social-icons.component.html',
    styleUrls: [ './social-icons.component.scss' ],
})
export class SocialIconsComponent {

    @Input() facebookUrl?: string;
    @Input() instagramUrl?: string;

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Area } from '../models/area.model';

@Injectable({
    providedIn: 'root',
})
export class ContactService {

    constructor(private http: HttpClient) {}

    send(area: Area, enquiry: { name: string; email: string; message: string }) {
        return this.http.post('https://api.be-local.uk/contact', enquiry, {
            params: {
                areaId: area._id,
            },
        });
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContainerModule } from '../../components/container/container.module';
import { FormModule } from '../../components/form/form.module';
import { LoaderModule } from '../../components/loader/loader.module';
import { MessageBlockModule } from '../../components/message-block/message-block.module';
import { SocialIconsModule } from '../../components/social-icons/social-icons.module';

import { ContactUsPageComponent } from './contact-us-page.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ContainerModule,
        FormModule,
        LoaderModule,
        MessageBlockModule,
        SocialIconsModule,
    ],
    declarations: [
        ContactUsPageComponent,
    ],
    exports: [
        ContactUsPageComponent,
    ],
})
export class ContactUsPageModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContainerModule } from '../../components/container/container.module';

import { SubscribePageComponent } from './subscribe-page.component';
import { SocialIconsModule } from '../../components/social-icons/social-icons.module';
import { LoaderModule } from '../../components/loader/loader.module';
import { RouterModule } from '@angular/router';
import { HowItWorksModule } from "../../components/how-it-works/how-it-works.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ContainerModule,
        SocialIconsModule,
        LoaderModule,
        RouterModule,
        HowItWorksModule,
    ],
    declarations: [
        SubscribePageComponent,
    ],
    exports: [
        SubscribePageComponent,
    ],
})
export class SubscribePageModule {}

<footer class="footer">
    <div class="wrapper">
        <div class="column">
            <nav class="navigation">
                <ul class="links">
                    <li>
                        <a class="link" routerLink="/your-local-guide">Your local guide</a>
                    </li>
                    <li>
                        <a class="link" routerLink="/how-it-works">How it works</a>
                    </li>
                    <li>
                        <a class="link" routerLink="/contact-us">Contact us</a>
                    </li>
                    <li>
                        <a class="link" routerLink="/terms-and-conditions">Terms and conditions</a>
                    </li>
                    <li>
                        <a class="link" routerLink="/privacy-policy">Privacy policy</a>
                    </li>
                </ul>
            </nav>

            <nav class="navigation">
                <ul class="links links--inline">
                    <li>
                        <a
                            href="https://www.facebook.com/homedinesolihull-113661703695253"
                            target="_blank"
                            class="link"
                        >
                            <img src="/assets/images/logos/facebook.png" class="social-icon">
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.instagram.com/homedinesolihull"
                            target="_blank"
                            class="link"
                        >
                            <img src="/assets/images/logos/instagram.png" class="social-icon">
                        </a>
                    </li>
                </ul>
            </nav>

            <p class="copyright">&copy; {{ year }} home dine club ltd</p>
        </div>

        <div class="column column--download-app">
            <h3 class="download-heading">Download the award winning app</h3>

            <div class="download-buttons">
                <a
                    href="https://apps.apple.com/gb/app/home-dine/id1550837261"
                    class="download-button"
                    target="_blank"
                >
                    <img
                        src="/assets/images/download-on-app-store.svg"
                        class="download-button__image"
                    >
                </a>

                <a
                    href="https://play.google.com/store/apps/details?id=uk.co.homeDine.homeDine&hl=en&gl=gb"
                    class="download-button"
                    target="_blank"
                >
                    <img
                        src="/assets/images/get-it-on-google-play.png"
                        class="download-button__image"
                    >
                </a>
            </div>
        </div>
    </div>
</footer>

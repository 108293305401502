<a
    *ngIf="hover.observers.length"
    href="#"
    class="view"
    (click)="$event.preventDefault(); hover.emit(establishment);"
>
    Show location
</a>

<a
    href="#"
    class="establishment"
    [class.establishment--loading]="!imageLoaded"
    (click)="$event.preventDefault(); view.emit(establishment)"
>
    <img
        *ngIf="full"
        class="establishment__image"
        [attr.src]="establishment.image"
        [attr.alt]="establishment.name"
        (load)="onLoadImage()"
    >

    <div class="establishment__details">

        <span
            *ngIf="establishment.offerText"
            class="establishment__offer"
        >
            <span class="establishment__offer-emphasise">
                {{ establishment.offerText }}
            </span>

            <span *ngIf="establishment.offerAvailabilitySummary">
                {{ establishment.offerAvailabilitySummary }}
            </span>
        </span>

        <div class="establishment__row">

            <div
                *ngIf="!full"
                class="establishment__image-wrapper"
            >
                <img
                    class="establishment__image"
                    [attr.src]="establishment.image"
                    [attr.alt]="establishment.name"
                    (load)="onLoadImage()"
                >
            </div>

            <div class="establishment__pad">

                <div class="establishment__details-head">
                    <div>
                        <h3 class="establishment__title">{{ establishment.name }}</h3>

                        <p class="establishment__description">{{ establishment.shortDescription }}</p>
                    </div>

                    <div
                        *ngIf="full"
                        class="establishment__logo-wrapper"
                    >
                        <img
                            *ngIf="establishment.logo"
                            class="establishment__logo"
                            [attr.alt]="establishment.name + ' logo'"
                            [attr.src]="establishment.logo"
                        >
                    </div>
                </div>

                <div class="establishment__address">
                    {{ establishment.address.addressLine2 || establishment.address.town }}<ng-container *ngIf="establishment.address.postcode">, {{ establishment.address.postcode.split(' ')[0] }}</ng-container>
                </div>
            </div>

        </div>

        <div
            *ngIf="full"
            class="establishment__fulfilments"
        >
            <span
                *ngFor="let fulfilmentOption of fulfilmentOptions(establishment.fulfilment); trackBy: fulfilmentTrackBy"
                [class.establishment__fulfilment--available]="fulfilmentOption.available"
                class="establishment__fulfilment"
            >
                {{ fulfilmentOption.fulfilment }}
            </span>
        </div>
    </div>
</a>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Area } from '../models/area.model';
import { Establishment } from '../models/establishment.model';
import { EstablishmentSimple } from '../models/establishment-simple.model';

@Injectable({
    providedIn: 'root',
})
export class EstablishmentService {

    static caches = {};

    constructor(private http: HttpClient) {}

    getCategories({ _id }: Area): Observable<{ name: string; description: string; establishments: EstablishmentSimple[]; }[]> {
        const url = `https://api.be-local.uk/establishments/grouped/categories?areaId=${_id}`;

        if (EstablishmentService.caches[url]) {
            return of(EstablishmentService.caches[url]);
        }

        return this.http.get<{ name: string; description: string; establishments: EstablishmentSimple[]; }[]>(url)
            .pipe(
                tap(response => {
                    EstablishmentService.caches[url] = response;
                })
            );
    }

    getOne(slug: string): Observable<Establishment> {
        const url = `https://api.be-local.uk/establishments/${slug}`;

        if (EstablishmentService.caches[url]) {
            return of(EstablishmentService.caches[url]);
        }

        return this.http.get<Establishment>(`https://api.be-local.uk/establishments/${slug}`)
            .pipe(
                tap(response => {
                    EstablishmentService.caches[url] = response;
                })
            );
    }
}

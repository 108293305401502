import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hd-message-block',
    templateUrl: './message-block.component.html',
    styleUrls: [ './message-block.component.scss' ],
})
export class MessageBlockComponent {

    @HostBinding('class') @Input() severity: '' | 'success' | 'error' = '';

}

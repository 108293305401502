import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { environment } from '../../../environments/environment';

import { EstablishmentSimple } from '../../models/establishment-simple.model';
import { Fulfilment } from '../../models/fulfilment.model';

@Component({
    selector: 'hd-establishment-block',
    templateUrl: './establishment-block.component.html',
    styleUrls: [ './establishment-block.component.scss' ],
})
export class EstablishmentBlockComponent {

    @Input() establishment: EstablishmentSimple;
    @HostBinding('class.full') @Input() full = true;
    @HostBinding('class.highlight') @Input() highlight = false;

    @Output() view = new EventEmitter<EstablishmentSimple>();
    @Output() hover = new EventEmitter<EstablishmentSimple>();
    @Output() leave = new EventEmitter<EstablishmentSimple>();

    imageLoaded = false;

    static FULFILMENTS = {
        1: 'Delivery',
        2: 'Takeaway',
        3: 'Visit',
    };

    fulfilmentOptions(fulfilments: Fulfilment[]) {
        return Object.entries(EstablishmentBlockComponent.FULFILMENTS)
            .map(([ key, fulfilment ]) => ({
                key,
                fulfilment,
                available: fulfilments.includes(Number(key)),
            }));
    }

    fulfilmentTrackBy(index) {
        return index;
    }

    onLoadImage() {
        this.imageLoaded = true;
    }
}

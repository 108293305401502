import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessageBlockComponent } from './message-block.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        MessageBlockComponent,
    ],
    exports: [
        MessageBlockComponent,
    ],
})
export class MessageBlockModule {}

import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'hd-welcome-modal',
    templateUrl: './welcome-modal.component.html',
    styleUrls: [ './welcome-modal.component.scss' ],
})
export class WelcomeModalComponent {

    @HostBinding('class.visible') visible = true;

    onClose() {
        this.visible = false;
    }
}

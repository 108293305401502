import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HowItWorksComponent } from './how-it-works.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
    declarations: [
        HowItWorksComponent,
    ],
    exports: [
        HowItWorksComponent,
    ],
})
export class HowItWorksModule {}

import { ChangeDetectorRef, Inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { BaseComponent } from '../base-component.class';

export abstract class InputComponent<T = any> extends BaseComponent {

    @Input() set control(control: FormControl) {
        this.destroyPreviousControl();

        this._control = control;

        if (this.setupControl) {
            this.setupControl();
        }

        this.setupControlDependencies();
    }
    @Input() errorMessage: string;
    @Input() label: string;
    @Input() set name(name: string) {
        this._name = name;

        this.id = `input-${this.name ? this.name : Math.round(Math.random() * Math.pow(10, 10))}`;
    }
    @Input() example?: string;
    @Input() information?: string;

    get control() {
        return this._control;
    }

    get name() {
        return this._name;
    }

    get invalid() {
        return this.control && this.control.invalid && this.control.touched;
    }

    controlDestroyed$ = new Subject<void>();

    _control: FormControl;
    _name: string;

    id: string;

    constructor(@Inject(ChangeDetectorRef) private baseChangeDetector) {
        super();
    }


    private destroyPreviousControl() {

        /**
         * If a new control is bound, we need to destroy the previous
         * subscribers
         */
        this.controlDestroyed$.next();
        this.controlDestroyed$.complete();

        this.controlDestroyed$ = new Subject<void>();
    }

    setupControl?(): void;
    setValidators?(): void;

    private setupControlDependencies() {
        if (!this.control || !this.setValidators) {
            return;
        }

        this.setValidators();
        this.baseChangeDetector.detectChanges();
        this.control.updateValueAndValidity();
    }
}

<div [@fadeIn]>

    <h1 class="title">Sign up</h1>

    <p class="text">
        Get discounts at the independents in all the areas on the be local app
        for just £1.99 a month. You can cancel at anytime.
    </p>

    <form (submit)="onCreateCustomer(form)">

        <hd-message-block
            *ngIf="error"
            severity="error"
        >
            Sorry, there was an issue creating your account. Please try again
        </hd-message-block>

        <hd-form-group>
            <hd-text-input
                errorMessage="Please enter your first name"
                label="First name"
                name="firstName"
                [control]="firstNameControl"
            ></hd-text-input>
        </hd-form-group>

        <hd-form-group>
            <hd-text-input
                errorMessage="Please enter your last name"
                label="Last name"
                name="lastName"
                [control]="lastNameControl"
            ></hd-text-input>
        </hd-form-group>

        <hd-form-group>
            <hd-email-input
                errorMessage="Please enter a valid email address"
                label="Email"
                name="email"
                [control]="emailControl"
            ></hd-email-input>
        </hd-form-group>

        <hd-form-group>
            <hd-address-lookup
                errorMessage="Please enter a valid postcode"
                label="Postcode"
                name="postcode"
                [control]="locationControl"
            ></hd-address-lookup>
        </hd-form-group>

        <hd-form-group>
            <hd-password-input
                errorMessage="Please enter a valid password"
                label="Password"
                name="password"
                [control]="passwordControl"
                [validate]="true"
                [showRules]="true"
            ></hd-password-input>
        </hd-form-group>

        <hd-form-group>
            <p>Please select the area where you'll use the app the most so we can set your default area. You can change your area in the app at any time and use your membership across all areas</p>

            <hd-loader
                *ngIf="areasLoading"
                colour="#fff"
            ></hd-loader>

            <hd-select-input
                *ngIf="!areasLoading"
                label="Area"
                name="area"
                [control]="areaControl"
                [comparator]="areaComparator"
                [options]="areaOptions"
            ></hd-select-input>
        </hd-form-group>

        <hd-form-group>
            <hd-checkbox
                name="terms"
                [control]="termsControl"
            >
                I agree to the <a routerLink="/terms-and-conditions" target="_blank">terms and conditions</a>
            </hd-checkbox>
        </hd-form-group>

        <hd-form-group>
            <hd-checkbox
                label="I agree to being contacted about further offers and news about independents"
                name="comms"
                [control]="commsControl"
            ></hd-checkbox>
        </hd-form-group>

        <button
            class="button"
            [disabled]="creatingAccount"
        >

            <ng-container *ngIf="!creatingAccount">
                Register
            </ng-container>

            <hd-loader
                *ngIf="creatingAccount"
                [small]="true"
                [flush]="true"
            ></hd-loader>

        </button>
    </form>

</div>
import { Component } from '@angular/core';
import { startWith } from 'rxjs/operators';
import { trigger } from '@angular/animations';

import { AreaService } from '../../services/area.service';
import { fadeInAnimation } from '../../animations/fade-in';

@Component({
    selector: 'hd-local-guide-page',
    templateUrl: './your-local-guide-page.component.html',
    styleUrls: [ './your-local-guide-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class YourLocalGuidePageComponent {

    area$ = AreaService.changed$.pipe(startWith(AreaService.selected));

}

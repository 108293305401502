import { Component, OnInit } from '@angular/core';
import { filter, startWith } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger } from '@angular/animations';

import { Area } from '../../models/area.model';
import { AreaService } from '../../services/area.service';
import { ContactService } from '../../services/contact.service';
import { fadeInAnimation } from '../../animations/fade-in';

@Component({
    selector: 'hd-contact-us-page',
    templateUrl: './contact-us-page.component.html',
    styleUrls: [ './contact-us-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class ContactUsPageComponent implements OnInit {

    area: Area;
    form: FormGroup;
    error = false;
    sending = false;
    sent = false;

    get nameControl() { return this.form.get('name') as FormControl; }
    get emailControl() { return this.form.get('email') as FormControl; }
    get messageControl() { return this.form.get('message') as FormControl; }

    constructor(
        private fb: FormBuilder,
        private contactService: ContactService,
    ) {
        this.form = fb.group({
            name: [ '', Validators.required ],
            email: [ '', Validators.required ],
            message: [ '', Validators.required ],
        })
    }

    ngOnInit() {
        AreaService.changed$
            .pipe(
                startWith(AreaService.selected),
                filter(Boolean),
            )
            .subscribe((area: Area) => {
                this.area = area;
            });
    }

    onSubmit(form: FormGroup) {
        if (form.invalid) {
            this.nameControl.markAsTouched();
            this.emailControl.markAsTouched();
            this.messageControl.markAsTouched();

            return;
        }

        this.sending = true;
        this.sent = false;

        this.contactService.send(AreaService.selected, form.value)
            .subscribe({
                next: () => {
                    this.sending = false;
                    this.sent = true;
                    this.error = false;

                    this.form.reset({});
                },
                error: () => {
                    this.error = true;
                    this.sending = false;
                },
            });
    }
}

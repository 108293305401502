<h3 class="follow-us">Solihull follows us</h3>

<p class="follow-us-description">
    Follow us to keep up with the latest from your local independents and our latest dining experiences.
</p>

<hd-social-icons
    *ngIf="showSocialIcons"
    class="social-icons"
></hd-social-icons>

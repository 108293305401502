<hd-loader *ngIf="loading"></hd-loader>

<div
    *ngIf="!loading"
    [@fadeIn]
>
    <hd-splash
        [image]="blog.headerImage"
        [title]="blog.title"
    ></hd-splash>

    <div
        class="html"
        [innerHTML]="blog.content"
    ></div>
</div>

import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'mc-input-wrapper',
    templateUrl: './input-wrapper.component.html',
    styleUrls: [ './input-wrapper.component.scss' ],
})
export class InputWrapperComponent implements OnInit, OnDestroy {

    @Input() errorMessage: string;
    @Input() invalid: boolean;
    @Input() label: string;
    @Input() inputId: string = null;
    @Input() information?: string;
    @Input() reason: string;
    @HostBinding('class.confirmation-visible') @Input() confirmationVisible = false;

    @ViewChild('informationIcon') informationIcon: ElementRef;

    informationPopupVisible = false;

    private clickOutsideEventListener: () => void;

    constructor(private renderer: Renderer2) {}

    ngOnInit() {
        if (this.information) {
            this.addClickOutsideEventListener();
        }
    }

    toggleInformationPopup() {
        this.informationPopupVisible = !this.informationPopupVisible;
    }

    ngOnDestroy() {
        if (!this.clickOutsideEventListener) {
            return;
        }

        this.clickOutsideEventListener();
    }

    private addClickOutsideEventListener() {
        this.clickOutsideEventListener = this.renderer.listen('window', 'click', ({ target }) => {
            if (target === this.informationIcon.nativeElement) {
                return;
            }

            this.informationPopupVisible = false;
        });
    }
}


<mc-input-wrapper
    [errorMessage]="errorMessage"
    [invalid]="invalid"
    [inputId]="id"
    [label]="label"
>

    <textarea
        class="input"
        rows="6"
        [attr.id]="id"
        [formControl]="control"
        [attr.placeholder]="example"
    ></textarea>

</mc-input-wrapper>

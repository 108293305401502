import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { Area } from '../../models/area.model';
import { AreaService } from '../../services/area.service';

@Component({
    selector: 'hd-select-area-modal',
    styleUrls: [ './select-area-modal.component.scss' ],
    templateUrl: './select-area-modal.component.html',
})
export class SelectAreaModalComponent implements OnInit {

    areas: Area[];

    @HostBinding('class.visible') @Input() visible = true;

    @Output() close = new EventEmitter();

    constructor(private areaService: AreaService) {
    }

    ngOnInit() {
        this.areaService.getAll()
            .subscribe(areas => {
                this.areas = areas;
            });
    }

    onChoose(area: Area) {
        this.close.emit();

        AreaService.updateSelected(area);
    }
}

<div class="filters">
    <div class="filter">
        <a
            href="#"
            class="control"
            (click)="$event.preventDefault(); openListOpen = !openListOpen"
        >
            When
        </a>

        <fieldset
            *ngIf="openListOpen"
            class="list"
            [class.list--open]="openListOpen"
        >
            <div class="list__item" *ngFor="let option of openOptions">
                <label
                    class="toggle__label"
                    [attr.for]="'open--' + option.value"
                    [class.toggle__label--active]="!openControl.value.length"
                >
                    <input
                        class="toggle__input"
                        type="checkbox"
                        [value]="option.value"
                        [attr.id]="'open--' + option.value"
                        (click)="onToggle(openControl, option.value)"
                        [checked]="openControl.value.includes(option.value)"
                    >

                    {{ option.label }}
                </label>
            </div>
        </fieldset>
    </div>

    <div class="filter">
        <a
            href="#"
            class="control"
            (click)="$event.preventDefault(); fulfilmentListOpen = !fulfilmentListOpen"
        >
            Type
        </a>

        <fieldset
            *ngIf="fulfilmentListOpen"
            class="list"
            [class.list--open]="fulfilmentListOpen"
        >
            <div class="list__item" *ngFor="let option of fulfilmentOptions">
                <label
                    class="toggle__label"
                    [attr.for]="'fulfilment--' + option.value"
                    [class.toggle__label--active]="!fulfilmentControl.value.length"
                >
                    <input
                        class="toggle__input"
                        type="checkbox"
                        [value]="option.value"
                        [attr.id]="'fulfilment--' + option.value"
                        (click)="onToggle(fulfilmentControl, option.value)"
                        [checked]="fulfilmentControl.value.includes(option.value)"
                    >

                    {{ option.label }}
                </label>
            </div>
        </fieldset>
    </div>
</div>

<a
    class="view-button"
    href="#"
    [class.view-button--map]="!mapView"
    [class.view-button--list]="mapView"
    (click)="$event.preventDefault(); toggleView.emit()"
></a>

import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger } from '@angular/animations';

import slugify from 'slugify';

import { EstablishmentService } from '../../services/establishment.service';
import { fadeInAnimation } from '../../animations/fade-in';
import { EstablishmentSimple } from '../../models/establishment-simple.model';
import { mapStyles } from '../../models/map.styles';
import { AreaService } from '../../services/area.service';
import { delay, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../components/base-component.class';

@Component({
    selector: 'hd-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: [ './home-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class HomePageComponent extends BaseComponent implements OnInit {

    readonly mapStyles = mapStyles;

    area = AreaService.selected;

    mapVisible = this.route.snapshot.queryParams.hasOwnProperty('map');

    categories: {
        name: string;
        description: string;
        establishments: EstablishmentSimple[]
    }[] = [];
    filteredCategories: {
        name: string;
        description: string;
        establishments: EstablishmentSimple[]
    }[] = [];
    filteredEstablishments: EstablishmentSimple[];
    activeEstablishment: EstablishmentSimple;
    establishmentsInView: EstablishmentSimple[];
    loading = false;

    latLow: number;
    latHigh: number;
    longitudeLow: number;
    longitudeHigh: number;

    @ViewChildren('establishmentBlockComponent', { read: ElementRef }) establishmentBlockComponents: QueryList<ElementRef>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private establishmentService: EstablishmentService,
    ) {
        super();
    }

    ngOnInit() {
        AreaService.changed$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(area => {
                this.area = area;

                this.loadData();
            });

        this.loadData();

        this.route.queryParams.subscribe(queryParams => {
            this.mapVisible = queryParams.hasOwnProperty('map');
        })
    }

    loadData() {
        this.loading = true;

        this.establishmentService.getCategories(AreaService.selected)
            .pipe(delay(50)) // Delay allow loader to show for cache areas
            .subscribe(categories => {
                this.loading = false;
                this.categories = categories;
                this.filteredCategories = this.applyFilters();
            });
    }

    applyFilters(fulfilments = [], open = []) {
        const filteredCategories = this.categories
            .map(category => ({
                ...category,
                establishments: category.establishments.filter(establishment =>
                    (!fulfilments.length || establishment.fulfilment.some(f => fulfilments.includes(f))) &&
                    (!open.length || open.every(day => Boolean(establishment.offerAvailability?.[day]))),
                ),
            }))
            .filter(({ establishments }) => establishments.length);

        this.filteredEstablishments = filteredCategories
            .map(({ establishments }) => establishments)
            .reduce((arr, establishments) => [ ...arr, ...establishments ])
            .filter(({ location }) => Boolean(location?.lat && location?.lng));

        this.activeEstablishment = this.filteredEstablishments[0];

        return filteredCategories;
    }

    categoryTrackBy(index: any, category: any) {
        return category.id;
    }

    establishmentTrackBy(index: any, establishment: EstablishmentSimple) {
        return establishment._id;
    }

    onViewEstablishment({ slug }: EstablishmentSimple) {
        this.router.navigate([ 'establishments', slug ]);
    }

    onFilter({ fulfilment, open }) {
        this.filteredCategories = this.applyFilters(fulfilment, open);

        this.filterInViewEstablishments();
    }

    onMarkerClick(selectedEstablishment: EstablishmentSimple) {
        const index = this.establishmentsInView.findIndex(establishment => establishment === selectedEstablishment);
        const element = this.establishmentBlockComponents.toArray()[index].nativeElement;

        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });

        this.activeEstablishment = selectedEstablishment;
    }

    onHoverEstablishment(establishment: EstablishmentSimple) {
        this.activeEstablishment = establishment;
    }

    onToggleView() {
        this.router.navigate(['/home'], {
            queryParams: {
                ...!this.mapVisible && { map: true },
                ...!this.mapVisible && { hideMembershipBar: true },
            }
        })
    }

    onActiveEstablishmentChange(establishment: EstablishmentSimple) {
        this.activeEstablishment = establishment;
    }

    onBoundsChange(event: any) {
        this.latLow = event.getSouthWest().lat();
        this.latHigh = event.getNorthEast().lat();
        this.longitudeLow = event.getSouthWest().lng();
        this.longitudeHigh = event.getNorthEast().lng();

        this.filterInViewEstablishments();
    }

    filterInViewEstablishments() {
        this.establishmentsInView = this.filteredEstablishments.filter(({ location: { lat, lng } }) =>
            lat >= this.latLow && lat <= this.latHigh && lng >= this.longitudeLow && lng <= this.longitudeHigh
        );
    }
}

<div class="wrapper" [@fadeIn]>

    <ng-container *ngIf="!subscriptionActive">

        <h1 class="title title--less-margin">
            Subscribe
        </h1>

        <h2 class="sub-title">
            OFFER: FREE MEMBERSHIP FOR 1 MONTH
        </h2>

        <hd-loader *ngIf="processingSubscription"></hd-loader>

        <div [hidden]="processingSubscription">
            <p class="text">
                You're just one step away from instant access to discounts at over 100 independents.
                Subscribe today to get your first month free, use voucher code <strong>HD1MONTH</strong>.
            </p>

            <p class="text">
                After your free month your membership will be £1.99 a month. You can
                cancel any time via the manage my subscription page.
            </p>

            <form (submit)="onSetupSubscription(promotionalCodeInput.value)" #subscriptionForm>

                <div class="form__group">
                    <div id="card-element" class="MyCardElement"></div>
                    <div
                        class="input-error"
                        role="alert"
                        [class.input-error--visible]="paymentError"
                    >
                        {{ paymentError }}
                    </div>
                </div>

                <div class="form__group">
                    <Label
                        class="label"
                        for="promotional-code"
                    >
                        Promotional Code
                    </Label>
                    <div class="promotional-code-wrapper">
                        <input
                            class="input input--promotional-code"
                            id="promotional-code"
                            [class.input--error]="promotionalCodeError"
                            #promotionalCodeInput
                        >
                    </div>
                    <div
                        class="input-error"
                        role="alert"
                        [class.input-error--visible]="promotionalCodeError"
                    >
                        {{ promotionalCodeError }}
                    </div>
                </div>

                <button class="button">Subscribe</button>
            </form>

        </div>

    </ng-container>

    <hd-how-it-works
        *ngIf="subscriptionActive"
        [user]="user$ | async"
    ></hd-how-it-works>

</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContainerModule } from '../../components/container/container.module';

import { PageWrapperComponent } from './page-wrapper.component';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '../../components/header/header.module';
import { FooterModule } from '../../components/footer/footer.module';
import { LoaderModule } from '../../components/loader/loader.module';
import { MembershipBarModule } from '../../components/membership-bar/membership-bar.module';
import { WalletBarModule } from '../../components/wallet-bar/wallet-bar.module';
import { SelectAreaModalModule } from '../../components/select-area-modal/select-area-modal.module';

@NgModule({
    imports: [
        CommonModule,

        HeaderModule,
        FooterModule,
        ContainerModule,
        LoaderModule,
        MembershipBarModule,
        WalletBarModule,
        SelectAreaModalModule,
        RouterModule,
    ],
    declarations: [
        PageWrapperComponent,
    ],
    exports: [
        PageWrapperComponent,
    ],
})
export class PageWrapperModule {}

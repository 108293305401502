import { Component } from '@angular/core';

import { InputComponent } from '../input-component.class';

@Component({
    selector: 'hd-email-input',
    templateUrl: './email-input.component.html',
    styleUrls: [ './email-input.component.scss' ],
})
export class EmailInputComponent extends InputComponent<string> {}

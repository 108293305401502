<div
    *ngIf="!establishmentLoading && establishment"
    [@fadeIn]
>

    <hd-splash
        [title]="establishment.name"
        [description]="establishment.shortDescription"
        [image]="establishment.headerImage"
    ></hd-splash>

    <section
        *ngIf="establishment.offerText"
        class="membership-block"
    >
        <div class="membership-block__content">
            <p
                *ngIf="!(authenticated$ | async)"
                class="membership-block__heading"
            >
                Sign up or login for offer
            </p>

            <p class="membership-block__offer-text">
                {{ establishment.offerText }} {{ establishment.offerAvailabilitySummary }}
            </p>

            <p
                *ngIf="establishment.offerDetails"
                class="membership-block__offer-details"
            >
                {{ establishment.offerDetails }}
            </p>

            <div class="membership-block__buttons">
                <a
                    *ngIf="authenticated$ | async"
                    routerLink="/wallet"
                    class="membership-block__button"
                    [queryParams]="{ term: establishment.name }"
                    (click)="onTrack('Show Discount Card')"
                >
                    Show discount card
                </a>

                <ng-container *ngIf="!(authenticated$ | async)">
                    <a
                        routerLink="/register"
                        class="membership-block__button membership-block__button--emphasise"
                    >
                        Sign up
                    </a>

                    <a
                        routerLink="/login"
                        class="membership-block__button"
                        [queryParams]="{ redirect: url }"
                    >
                        Login
                    </a>
                </ng-container>
            </div>
        </div>
    </section>

    <div class="wrapper">

        <hd-container [block]="true">

            <p class="description">
                {{ establishment.description }}
            </p>

        </hd-container>

        <div class="fulfilments">
             <span
                 *ngFor="let fulfilmentOption of fulfilmentOptions(establishment.fulfilment); trackBy: fulfilmentTrackBy"
                 [class.fulfilment--available]="fulfilmentOption.available"
                 class="fulfilment"
             >
                {{ fulfilmentOption.fulfilment }}
            </span>
        </div>

        <div class="columns">

            <div *ngIf="establishment.gallery && establishment.gallery.length"
                class="column column--gallery"
            >
                <ul class="gallery">
                    <li
                        *ngFor="let image of establishment.gallery"
                        class="gallery__item"
                    >
                        <div
                            class="gallery__placeholder-image"
                            [class.gallery__placeholder-image--hidden]="imagesLoaded[image]"
                        ></div>

                        <img
                            class="gallery__image"
                            [attr.src]="image"
                            (load)="imagesLoaded[image] = true"
                        >
                    </li>
                </ul>
            </div>

            <div
                *ngIf="establishment.reviews && establishment.reviews[0]"
                class="column column--review"
            >
                <article class="review">
                    <h3 class="review__title">What we tried&hellip;</h3>

                    <p class="review__review">
                        {{ establishment.reviews[0].review }}
                    </p>

                    <p class="review__reviewer">
                        <strong>{{ establishment.reviews[0].reviewer }}</strong> - {{ establishment.reviews[0].date | date }}
                    </p>

                    <img
                        class="review__image"
                        [attr.src]="establishment.reviews[0].image"
                    >

                    <p class="review__product-name">
                        {{ establishment.reviews[0].product_name }}
                    </p>

                    <p class="review__product-price">
                        {{ establishment.reviews[0].product_price | currency : "GBP" }}
                    </p>

                </article>
            </div>
        </div>

        <div class="details-columns">

            <address class="details">
                <a
                    *ngIf="establishment.website"
                    class="detail detail--website"
                    rel="noopener"
                    target="_blank"
                    [attr.href]="establishment.website"
                    (click)="onTrack('Website Link')"
                >
                    Website
                </a>

                <a
                    *ngIf="establishment.menuUrl"
                    class="detail detail--menu"
                    rel="noopener"
                    target="_blank"
                    [attr.href]="establishment.menuUrl"
                    (click)="onTrack('Menu Link')"
                >
                    Menu
                </a>

                <a
                    *ngIf="establishment.bookingUrl"
                    class="detail detail--book-a-table"
                    rel="noopener"
                    target="_blank"
                    [attr.href]="establishment.bookingUrl"
                    (click)="onTrack('Book a Table Link')"
                >
                    Book a Table
                </a>

                <a
                    *ngIf="establishment.shopUrl"
                    class="detail detail--shop"
                    rel="noopener"
                    target="_blank"
                    [attr.href]="establishment.shopUrl"
                    (click)="onTrack('Shop Link')"
                >
                    Shop
                </a>

                <a
                    *ngIf="establishment.telephone"
                    class="detail detail--telephone"
                    [attr.href]="'tel:' + establishment.telephone"
                    (click)="onTrack('Telephone Link')"
                >
                    {{ establishment.telephone }}
                </a>

                <a
                    *ngIf="establishment.email"
                    class="detail detail--email"
                    [attr.href]="'mailto:' + establishment.email"
                    (click)="onTrack('Email')"
                >
                    {{ establishment.email }}
                </a>

                <a
                    *ngIf="establishment.instagramUrl"
                    class="detail detail--instagram"
                    rel="noopener"
                    target="_blank"
                    [attr.href]="establishment.instagramUrl"
                    (click)="onTrack('Instagram')"
                >
                    {{ instagramUsername }}
                </a>

                <a
                    *ngIf="establishment.facebookUrl"
                    class="detail detail--facebook"
                    rel="noopener"
                    target="_blank"
                    [attr.href]="establishment.facebookUrl"
                    (click)="onTrack('Facebook')"
                >
                    Facebook
                </a>

                <div
                    *ngIf="establishment.showOpeningTimes"
                    class="detail detail--opening"
                >
                    <div class="detail__multi-wrapper">
                        <p class="detail__heading">Opening Times</p>

                        <p class="caveat">Due to Covid-19 hours may differ. Please check website for up to date details</p>

                        <table class="opening-times-table">
                            <tr
                                *ngFor="let openingTime of openingTimes"
                                class="opening-times-table__row"
                                [class.opening-times-table__row--highlight]="openingTime.isToday"
                            >
                                <td class="opening-times-table__cell">{{ openingTime.day }}</td>
                                <td class="opening-times-table__cell opening-times-table__cell--times">
                                    <ng-container *ngIf="openingTime.open && openingTime.close">
                                        {{ openingTime.open }} - {{ openingTime.close }}
                                    </ng-container>

                                    <ng-container *ngIf="!openingTime.open && !openingTime.close">
                                        Closed
                                    </ng-container>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <a
                    target="_blank"
                    class="detail detail--address"
                    [attr.href]="addressUrl"
                    (click)="onTrack('Address')"
                >
                    <div class="detail__multi-wrapper">
                        <p class="detail__heading">Address</p>

                        <p class="detail__text">
                            <ng-container *ngIf="establishment.address.addressLine1">{{ establishment.address.addressLine1 }}<br></ng-container>
                            <ng-container *ngIf="establishment.address.addressLine2">{{ establishment.address.addressLine2 }}<br></ng-container>
                            <ng-container *ngIf="establishment.address.town">{{ establishment.address.town }}<br></ng-container>
                            <ng-container *ngIf="establishment.address.county">{{ establishment.address.county }}<br></ng-container>
                            <ng-container *ngIf="establishment.address.postcode">{{ establishment.address.postcode }}</ng-container>
                        </p>
                    </div>
                </a>
            </address>

            <address
                *ngIf="establishment.location"
                class="details details--map"
            >
                <div class="detail detail--map">
                    <agm-map
                        class="map"
                        gestureHandling="greedy"
                        [clickableIcons]="false"
                        [latitude]="establishment.location.lat"
                        [longitude]="establishment.location.lng"
                        [zoom]="18"
                        [styles]="mapStyles"
                    >

                        <agm-overlay
                            *ngIf="establishment.logo"
                            [latitude]="establishment.location.lat"
                            [longitude]="establishment.location.lng"
                        >
                            <img
                                [attr.src]="establishment.logo"
                                class="map__icon"
                            >
                        </agm-overlay>

                        <agm-marker
                            *ngIf="!establishment.logo"
                            [latitude]="establishment.location.lat"
                            [longitude]="establishment.location.lng"
                            [markerClickable]="false"
                        ></agm-marker>

                    </agm-map>
                </div>
            </address>

        </div>

    </div>

</div>

<hd-loader *ngIf="establishmentLoading"></hd-loader>

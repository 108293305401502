<nav class="navigation">
    <a
        *ngIf="instagramUrl"
        [attr.href]="instagramUrl"
        class="navigation__link navigation__link--instagram"
        rel="noopener"
        target="_blank"
    ></a>

    <a
        *ngIf="facebookUrl"
        [attr.href]="facebookUrl"
        class="navigation__link navigation__link--facebook"
        rel="noopener"
        target="_blank"
    ></a>
</nav>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SocialBlockComponent } from './social-block.component';
import { SocialIconsModule } from '../social-icons/social-icons.module';

@NgModule({
    imports: [
        CommonModule,
        SocialIconsModule,
    ],
    declarations: [
        SocialBlockComponent,
    ],
    exports: [
        SocialBlockComponent,
    ],
})
export class SocialBlockModule {}

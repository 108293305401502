
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import { trigger } from '@angular/animations';

import { BaseComponent } from '../../components/base-component.class';
import { Blog } from '../../models/blog.model';
import { BlogService } from '../../services/blog.service';
import { fadeInAnimation } from '../../animations/fade-in';

@Component({
    selector: 'hd-blog-page',
    templateUrl: './blog-page.component.html',
    styleUrls: [ './blog-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class BlogPageComponent extends BaseComponent implements OnInit {

    loading = true;

    blog: Blog;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private blogService: BlogService,
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;

        this.blogService.getOne(this.route.snapshot.params.slug)
            .pipe(delay(50)) // Delay allows loader to show when cached
            .subscribe({
                next: blog => {
                    this.blog = blog;
                    this.loading = false;
                },
            });
    }
}

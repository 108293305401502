<mc-input-wrapper
    reason="To help find nearby independents"
    [errorMessage]="errorMessage"
    [invalid]="invalid"
    [inputId]="id"
    [label]="label"
>

    <input
        autocomplete="new-password"
        class="input"
        oninput="this.value = this.value.toUpperCase()"
        [attr.id]="id"
        [class.input--error]="control.invalid && control.touched"
        [(ngModel)]="postcode"
        (blur)="onBlur(postcode)"
    >

</mc-input-wrapper>
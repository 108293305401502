<hd-loader *ngIf="loading"></hd-loader>

<div
    *ngIf="!loading"
    [@fadeIn]
>
    <h1 class="title">Walks with independents</h1>

    <p class="text">
        We love nothing more than walking, especially when there are some great independents to explore along the
        way. We’ve featured all of our walks below for you to be able to view and then download the route using
        Outdooractive. We hope you enjoy these routes as much as we did and enjoy the independents at the same time.
        Take lots of pictures and tag us in, we love seeing them.
    </p>

    <ul class="list">
        <li
            class="list__item"
            *ngFor="let walk of walks"
        >
            <article class="walk">
                <img [attr.src]="walk.image" class="walk__image">
                <div class="walk__content">
                    <h2 class="walk__title">{{ walk.title }}</h2>
                    <p class="walk__excerpt">{{ walk.excerpt }}</p>
                    <a
                        href="#"
                        class="walk__button"
                        target="_blank"
                        (click)="$event.preventDefault(); onView(walk)"
                    >
                        View walk
                    </a>
                </div>
            </article>
        </li>
    </ul>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContainerModule } from '../../components/container/container.module';

import { BlogsPageComponent } from './blogs-page.component';
import { LoaderModule } from '../../components/loader/loader.module';

@NgModule({
    imports: [
        CommonModule,

        ContainerModule,
        LoaderModule,
    ],
    declarations: [
        BlogsPageComponent,
    ],
    exports: [
        BlogsPageComponent,
    ],
})
export class BlogsPageModule {}

import { catchError } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {AccountService} from "../services/account.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    /**
     * A disgusting hack to not handle 401 errors for some APIs
     * as they will be handled elsewhere
     */
    static IGNORED = [
        'login',
    ];

    constructor(private accountService: AccountService) {}


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (ErrorInterceptor.IGNORED.some(v => request.url.includes(v))) {
            return next.handle(request);
        }

        return next.handle(request)
            .pipe(
                catchError(error => {
                    if ([ 401, 403 ].includes(error.status)) {
                        this.accountService.logout();
                    }

                    return throwError(error);
                }),
            );
    }

}

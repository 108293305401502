import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DiscountCardComponent } from './discount-card.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
    declarations: [
        DiscountCardComponent,
    ],
    exports: [
        DiscountCardComponent,
    ],
})
export class DiscountCardModule {}

<hd-loader *ngIf="loading"></hd-loader>

<div
    *ngIf="!loading"
    [@fadeIn]
>
    <h1 class="title">Blogs</h1>

    <ul class="list">
        <li
            class="list__item"
            *ngFor="let blog of blogs"
        >
            <article class="blog">
                <div class="blog__image-wrapper">
                    <img [attr.src]="blog.image" class="blog__image">
                </div>
                <div class="blog__content">
                    <h2 class="blog__title">{{ blog.title }}</h2>
                    <p class="blog__excerpt">{{ blog.excerpt }}</p>
                    <a
                        href="#"
                        class="blog__button"
                        target="_blank"
                        (click)="$event.preventDefault(); onView(blog)"
                    >
                        Read more
                    </a>
                </div>
            </article>
        </li>
    </ul>
</div>

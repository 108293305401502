<div *ngIf="!loading">

    <hd-establishment-filters
        [mapView]="mapVisible"
        (filter)="onFilter($event)"
        (toggleView)="onToggleView()"
    ></hd-establishment-filters>

    <ng-container *ngIf="!mapVisible">

        <section *ngFor="let category of filteredCategories; trackBy: categoryTrackBy" class="category">

            <h1 class="category__title">{{ category.name }}</h1>

            <p class="category__description">{{ category.description }}</p>

            <hd-carousel>

                <hd-carousel-item *ngFor="let establishment of category.establishments; trackBy: establishmentTrackBy">

                    <hd-establishment-block
                        [establishment]="establishment"
                        (view)="onViewEstablishment($event)"
                    ></hd-establishment-block>

                </hd-carousel-item>

            </hd-carousel>

        </section>

    </ng-container>

    <div
        *ngIf="mapVisible"
        class="map-wrapper"
    >

        <agm-map
            class="map"
            gestureHandling="cooperative"
            [scrollwheel]="null"
            [clickableIcons]="false"
            [latitude]="area.location.lat"
            [longitude]="area.location.lng"
            [zoom]="10"
            [styles]="mapStyles"
            (boundsChange)="onBoundsChange($event)"
        >
            <ng-container *ngFor="let establishment of establishmentsInView; trackBy: establishmentTrackBy">
                <agm-marker
                    [iconUrl]="{
                          url: establishment === activeEstablishment
                              ? '/assets/images/map-pin-highlighted.png'
                              : '/assets/images/map-pin.png',
                          scaledSize: establishment === activeEstablishment
                              ? {
                                  width: 34,
                                  height: 47
                              }
                              : {
                                  width: 29,
                                  height: 40
                              }
                    }"
                    [animation]="establishment === activeEstablishment ? 'BOUNCE' : null"
                    [latitude]="establishment.location.lat"
                    [longitude]="establishment.location.lng"
                    [markerClickable]="true"
                    [zIndex]="establishment === activeEstablishment ? 101 : 100"
                    (markerClick)="onMarkerClick(establishment)"
                ></agm-marker>
            </ng-container>

        </agm-map>

        <div class="carousel">
            <hd-carousel
                [snap]="true"
                (activeItem)="onActiveEstablishmentChange(establishmentsInView[$event - 1])"
            >
                <hd-carousel-item
                    *ngFor="let establishment of establishmentsInView; trackBy: establishmentTrackBy"
                    [bottom]="true"
                >
                    <hd-establishment-block
                        [highlight]="establishment === activeEstablishment"
                        [establishment]="establishment"
                        [full]="false"
                        (view)="onViewEstablishment($event)"
                        (hover)="onHoverEstablishment($event)"
                        #establishmentBlockComponent
                    ></hd-establishment-block>
                </hd-carousel-item>
            </hd-carousel>
        </div>

    </div>

</div>

<hd-no-results *ngIf="!loading && !filteredCategories.length"></hd-no-results>

<hd-loader *ngIf="loading"></hd-loader>

<hd-social-section></hd-social-section>

<a
    class="link"
    routerLink="/wallet"
>
    <img
        src="/assets/images/icons/wallet.png"
        class="icon"
    >

    <div>
        <p class="title">Your discount wallet</p>
        <p class="text">Access you wallet to use your discount card</p>
    </div>
</a>

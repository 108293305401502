import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AddressService {

    constructor(private http: HttpClient) {}

    get(postcode: string) {
        return this.http.get<{}>(`https://api.be-local.uk/account/address/${encodeURIComponent(postcode)}`);
    }
}

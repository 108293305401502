<div [@fadeIn]>

    <h1 class="title">Your local guide</h1>

    <div
        class="html"
        [innerHTML]="(area$ | async).ourStory"
    ></div>

</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContainerModule } from '../../components/container/container.module';

import { RegistrationPageComponent } from './registration-page.component';
import { SocialIconsModule } from '../../components/social-icons/social-icons.module';
import { LoaderModule } from '../../components/loader/loader.module';
import { MessageBlockModule } from '../../components/message-block/message-block.module';
import { FormModule } from '../../components/form/form.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ContainerModule,
        SocialIconsModule,
        LoaderModule,
        MessageBlockModule,
        FormModule,
        RouterModule,
    ],
    declarations: [
        RegistrationPageComponent,
    ],
    exports: [
        RegistrationPageComponent,
    ],
})
export class RegistrationPageModule {}

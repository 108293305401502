import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { InputWrapperComponent } from './input-wrapper/input-wrapper.component';
import { LoaderModule } from '../loader/loader.module';
import { PasswordInputComponent } from './password-input/password-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { TextInputComponent } from './text-input/text-input.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoaderModule,
    ],
    declarations: [
        AddressLookupComponent,
        CheckboxComponent,
        EmailInputComponent,
        FormGroupComponent,
        InputWrapperComponent,
        PasswordInputComponent,
        SelectInputComponent,
        TextareaInputComponent,
        TextInputComponent,
    ],
    exports: [
        AddressLookupComponent,
        CheckboxComponent,
        EmailInputComponent,
        FormGroupComponent,
        PasswordInputComponent,
        SelectInputComponent,
        TextareaInputComponent,
        TextInputComponent,
    ],
})
export class FormModule {}

import { Component, OnInit } from '@angular/core';
import { delay, startWith, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { trigger } from '@angular/animations';

import { AreaService } from '../../services/area.service';
import { BaseComponent } from '../../components/base-component.class';
import { fadeInAnimation } from '../../animations/fade-in';
import { Walk } from '../../models/walk.model';
import { WalkService } from '../../services/walk.service';

@Component({
    selector: 'hd-walks-page',
    templateUrl: './walks-page.component.html',
    styleUrls: [ './walks-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class WalksPageComponent extends BaseComponent implements OnInit {

    loading = true;

    walks: Walk[];

    constructor(
        private router: Router,
        private walksService: WalkService,
    ) {
        super();
    }

    ngOnInit() {
        AreaService.changed$
            .pipe(
                startWith(AreaService.selected),
                takeUntil(this.destroyed$),
            )
            .subscribe(() => this.loadData());
    }

    loadData() {
        this.loading = true;

        this.walksService.getAll(AreaService.selected)
            .pipe(delay(50)) // Delay allows loader to show when cached
            .subscribe(walks => {
                this.walks = walks;
                this.loading = false;
            });
    }

    onView({ slug, url, hasContent }: Walk) {
        if (!hasContent) {
            return window.open(url, '_blank');
        }

        this.router.navigate([ '/walks', slug ]);
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelectAreaModalComponent } from './select-area-modal.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        SelectAreaModalComponent,
    ],
    exports: [
        SelectAreaModalComponent,
    ],
})
export class SelectAreaModalModule {}

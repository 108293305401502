<div class="wrapper">
    <div class="columns">
        <div class="column">
            <h2 class="title">Walks with independents</h2>

            <article class="block">
                <img
                    class="block__image"
                    src="/assets/images/walks.jpg"
                >

                <p class="block__text">
                    Explore local walks featuring lots of independent pit stops, pubs, cafes,
                    local produce shops and more.
                </p>

                <a
                    class="block__button"
                    routerLink="/walks"
                >
                    View all our walks
                </a>
            </article>
        </div>

        <div class="column">
            <h2 class="title">Blogs</h2>

            <article class="block">
                <img
                    class="block__image"
                    src="/assets/images/blog.jpg"
                >

                <p class="block__text">
                    We write lots of blogs about our independents to inspire you to support
                    them and make sure you're in the know
                </p>

                <a
                    class="block__button"
                    routerLink="/blogs"
                >
                    View all our blogs
                </a>
            </article>
        </div>
    </div>
</div>

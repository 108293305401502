import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { AddressService } from '../../../services/address.service';
import { InputComponent } from '../input-component.class';

@Component({
    selector: 'hd-address-lookup',
    templateUrl: './address-lookup.component.html',
    styleUrls: [ './address-lookup.component.scss' ],
})
export class AddressLookupComponent extends InputComponent {

    @Input() notListedLabel: string;
    @Input() selectedAccessor: (value: any) => string;

    @Output() notListed = new EventEmitter();

    @ViewChild('input', { static: false }) inputElement: ElementRef;
    loading = false;
    error = false;
    postcode = '';

    constructor(
        private element: ElementRef,
        changeDetector: ChangeDetectorRef,
        private addressService: AddressService
    ) {
        super(changeDetector);
    }

    onBlur(postcode: string) {
        this.addressService.get(postcode)
            .subscribe({
                next: address => {
                    this.control.setValue(address);
                },
                error: () => {
                    this.control.setValue(null);
                    this.control.markAsTouched();
                },
            });
    }
}

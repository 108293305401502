import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hd-social-block',
    templateUrl: './social-block.component.html',
    styleUrls: [ './social-block.component.scss' ],
})
export class SocialBlockComponent {

    @HostBinding('class.center') @Input() center = false;
    @Input() showSocialIcons = true;

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ContainerModule } from '../../components/container/container.module';

import { YourLocalGuidePageComponent } from './your-local-guide-page.component';

@NgModule({
    imports: [
        CommonModule,

        ContainerModule,
        RouterModule,
    ],
    declarations: [
        YourLocalGuidePageComponent,
    ],
    exports: [
        YourLocalGuidePageComponent,
    ],
})
export class YourLocalGuidePageModule {}

<mc-input-wrapper
    [errorMessage]="errorMessage"
    [invalid]="invalid"
    [inputId]="id"
>

    <label
        class="label"
        [attr.for]="id"
    >
        <input
            class="input"
            type="checkbox"
            [attr.id]="id"
            [name]="name"
            [formControl]="control"
        >

        <span class="checkbox"></span>

        <span>
            {{ label }} <ng-content></ng-content>
        </span>

        <span
            class="disabled"
            [class.disabled--visible]="control.disabled"
        ></span>
    </label>

</mc-input-wrapper>

import { Component, Input } from '@angular/core';

import { InputComponent } from '../input-component.class';

@Component({
    selector: 'hd-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: [ './text-input.component.scss' ],
})
export class TextInputComponent extends InputComponent<string> {

    @Input() showClear = false;

    onClear() {
        this.control.setValue('');
    }
}

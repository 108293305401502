import { Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { InputComponent } from '../input-component.class';

@Component({
    selector: 'hd-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: [ './select-input.component.scss' ],
})
export class SelectInputComponent extends InputComponent<any> {

    @Input() comparator: (a: any, b: any) => boolean;
    @Input() placeholder: string;
    @Input() options: { label: string; value: any; }[];

    selected: any;

    setupControl() {
        this.control.valueChanges
            .pipe(takeUntil(this.controlDestroyed$))
            .subscribe(value => {
                this.selected = value;
            });

        this.selected = this.control.value;
    }

    onBlur() {
        this.control.markAsTouched();
    }

    onChange(value) {
        this.control.markAsTouched();
        this.control.setValue(value);
    }

    compareWith = (a, b) => {
        return this.comparator(a, b);
    }

}

import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EstablishmentPageComponent } from './establishment-page.component';
import { ContainerModule } from '../../components/container/container.module';
import { HeaderModule } from '../../components/header/header.module';
import { LoaderModule } from '../../components/loader/loader.module';
import { SplashModule } from '../../components/splash/splash.module';

@NgModule({
    imports: [
        AgmCoreModule,
        AgmOverlays,
        CommonModule,

        ContainerModule,
        HeaderModule,
        LoaderModule,
        RouterModule,
        SplashModule,
    ],
    declarations: [
        EstablishmentPageComponent,
    ],
    exports: [
        EstablishmentPageComponent,
    ],
})
export class EstablishmentPageModule {}

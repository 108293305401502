import { Injectable } from '@angular/core';

import { ApiRequestService } from './api-request.service';
import { Area } from '../models/area.model';
import { Walk } from '../models/walk.model';

@Injectable({
    providedIn: 'root',
})
export class WalkService {

    constructor(private apiRequest: ApiRequestService) {}

    getAll(area: Area) {
        return this.apiRequest.get<Walk[]>(`https://api.be-local.uk/walks?areaId=${area._id}`, { useCache: true });
    }

    getOne(slug: string) {
        return this.apiRequest.get<Walk>(`https://api.be-local.uk/walks/${slug}`, { useCache: true });
    }

}

<div class="wrapper">

    <img src="/assets/images/logo-icon-text.png" class="logo">

    <p class="text text--light">
        Unearth those independent businesses we call hidden gems, enjoy hundreds of
        miles of circular walking routes and get exclusive discounts at all the
        businesses featured on our app.
    </p>

    <p class="text text--light">
        Each area is managed by a local guide who is passionate about sharing the best of their
        area with you.
    </p>

    <section class="panel">
        <h2 class="panel__heading">Where do you want to be local?</h2>

        <div class="areas">
            <a
                *ngFor="let area of areas"
                href="#"
                class="area"
                [style.backgroundColor]="area.primaryColour"
                (click)="$event.preventDefault(); onChoose(area)"
            >
                <img
                    class="area__logo"
                    [src]="area.logo"
                >

                {{ area.name }}
            </a>
        </div>

        <p class="text">You can switch areas at any time</p>
    </section>

    <div class="buttons">
        <a
            href="https://apps.apple.com/gb/app/home-dine/id1550837261"
            class="button"
            target="_blank"
        >
            <img
                src="/assets/images/download-on-app-store.svg"
                class="button__image"
            >
        </a>

        <a
            href="https://play.google.com/store/apps/details?id=uk.co.homeDine.homeDine&hl=en&gl=gb"
            class="button"
            target="_blank"
        >
            <img
                src="/assets/images/get-it-on-google-play.png"
                class="button__image"
            >
        </a>
    </div>

</div>
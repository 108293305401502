import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SocialSectionComponent } from './social-section.component';
import {RouterModule} from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
    declarations: [
        SocialSectionComponent,
    ],
    exports: [
        SocialSectionComponent,
    ],
})
export class SocialSectionModule {}

import { Component } from '@angular/core';
import { trigger } from '@angular/animations';

import { fadeInAnimation } from '../../animations/fade-in';

@Component({
    selector: 'hd-download-the-app-page',
    templateUrl: './download-the-app-page.component.html',
    styleUrls: [ './download-the-app-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class DownloadTheAppPageComponent {}

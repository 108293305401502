<a
    class="join"
    routerLink="/register"
>
    <div class="column">
        <p class="text text--price">
            First month <span class="price">FREE</span>
        </p>
        <div class="button button--register">
            Join now
        </div>
    </div>
    <div class="column">
        <p class="text">
            Sign up today to get your first month free, £1.99 a month thereafter.
        </p>
    </div>
</a>

<a
    class="login"
    routerLink="/login"
>
    <div class="column column--end">
        <div class="logo-wrapper">
            <img
                [attr.src]="logo"
                class="logo"
            >
        </div>
        <div class="button button--login">
            Login
        </div>
    </div>
</a>

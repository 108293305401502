import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger } from '@angular/animations';

import { fadeInAnimation } from '../../animations/fade-in';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'hd-reset-password-page',
    templateUrl: './change-password-page.component.html',
    styleUrls: [ './change-password-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class ChangePasswordPageComponent {

    form = new FormGroup({
        oldPassword: new FormControl('', { validators: [ Validators.required ], updateOn: 'blur' }),
        newPassword: new FormControl('', { validators: [ Validators.required ], updateOn: 'blur' }),
    });

    get oldPasswordControl() { return this.form.get('oldPassword') as FormControl; }
    get newPasswordControl() { return this.form.get('newPassword') as FormControl; }

    changing = false;
    changed = false;
    error = false;
    incorrectPassword = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
    ) {}

    onChange(form: FormGroup) {
        if (form.invalid) {
            this.oldPasswordControl.markAsTouched();
            this.newPasswordControl.markAsTouched();

            return;
        }

        this.changing = true;
        this.error = false;

        this.accountService.changePassword(form.value.oldPassword, form.value.newPassword)
            .subscribe({
                next: () => {
                    this.changing = false;
                    this.changed = true;
                },
                error: ({ code }) => {
                    this.changing = false;
                    this.error = true;
                    this.incorrectPassword = code === 'NotAuthorizedException';
                },
            });
    }

}

import { Component } from '@angular/core';

import { InputComponent } from '../input-component.class';

@Component({
    selector: 'hd-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: [ './checkbox.component.scss' ],
})
export class CheckboxComponent extends InputComponent<string[]> {

    onToggle() {

        /**
         * Best to maintain checked order with display order in case other
         * controls are driven by the control value
         */
        this.control.setValue(!this.control.value);
    }
}

<header class="header">

    <a class="logo-link" routerLink="/">
        <img
            class="logo"
            src="/assets/images/logo-text.png"
        >
    </a>

    <a
        *ngIf="showAreaSelector"
        class="select-area"
        href="#"
        (click)="$event.preventDefault(); viewAreaSelector.emit()"
    >
        <img
            class="select-area__logo"
            [src]="area.logo"
        >

        <span class="select-area__name">{{ area.name }}</span>
    </a>

    <a
        href="#"
        class="menu-link"
        (click)="$event.preventDefault(); openMenu()"
    >
        Menu
    </a>
</header>

<div
    [class.menu--visible]="menuVisible"
    class="menu"
>
    <a
        href="#"
        class="menu__close"
        (click)="$event.preventDefault(); closeMenu()"
    >
        Close menu
    </a>

    <img
        class="menu__logo"
        src="/assets/images/logo-text.png"
    >

    <div class="overflow">
        <div class="content">

            <p *ngIf="user$ | async" class="welcome-text">
                Hi {{ (user$ | async).given_name }}
            </p>

            <nav class="navigation">
                <ul class="navigation__list">

                    <ng-container *ngIf="!authenticated">
                        <li class="navigation__item navigation__item--emphasise navigation__item--login">
                            <a
                                routerLink="/login"
                                class="navigation__link"
                            >
                                Login
                            </a>
                        </li>

                        <li class="navigation__item navigation__item--emphasise navigation__item--register">
                            <a
                                routerLink="/register"
                                class="navigation__link"
                            >
                                Register
                            </a>
                        </li>
                    </ng-container>

                    <ng-container *ngIf="authenticated">
                        <li
                            *ngIf="!subscriptionActive"
                            class="navigation__item navigation__item--emphasise navigation__item--subscribe"
                        >
                            <a
                                routerLink="/subscribe"
                                class="navigation__link"
                            >
                                Subscribe
                            </a>
                        </li>

                        <li
                            *ngIf="subscriptionActive"
                            class="navigation__item navigation__item--wallet"
                        >
                            <a
                                routerLink="/wallet"
                                class="navigation__link"
                            >
                                Discount Wallet
                            </a>
                        </li>
                    </ng-container>

                    <li
                        *ngIf="showHome"
                        class="navigation__item navigation__item--home"
                    >
                        <a
                            class="navigation__link"
                            routerLink="/"
                        >
                            Independents
                        </a>
                    </li>

                    <li class="navigation__item navigation__item--blog">
                        <a
                            class="navigation__link"
                            routerLink="/blogs"
                        >
                            Blogs
                        </a>
                    </li>

                    <li class="navigation__item navigation__item--walks">
                        <a
                            class="navigation__link"
                            routerLink="/walks"
                        >
                            Walks with independents
                        </a>
                    </li>

                    <li class="navigation__item navigation__item--location">
                        <a
                            href="#"
                            class="navigation__link"
                            (click)="$event.preventDefault(); onViewAreaSelector()"
                        >
                            Change your location
                        </a>
                    </li>

                    <li class="navigation__item navigation__item--download-the-app">
                        <a
                            class="navigation__link"
                            routerLink="/download-the-app"
                        >
                            Download the app
                        </a>
                    </li>

                    <li class="navigation__item">
                        <a
                            class="navigation__link"
                            routerLink="/your-local-guide"
                        >
                            Your local guide
                        </a>
                    </li>

                    <li class="navigation__item">
                        <a
                            class="navigation__link"
                            routerLink="/how-it-works"
                        >
                            How it works
                        </a>
                    </li>
                    <li class="navigation__item">
                        <a
                            class="navigation__link"
                            routerLink="/contact-us"
                        >
                            Contact us
                        </a>
                    </li>

                    <ng-container *ngIf="authenticated">
                        <li
                            *ngIf="subscriptionActive"
                            class="navigation__item"
                        >
                            <a
                                href="#"
                                class="navigation__link"
                                (click)="$event.preventDefault(); manageSubscription.emit()"
                            >
                                Manage your subscription
                            </a>
                        </li>

                        <li class="navigation__item">
                            <a
                                class="navigation__link"
                                routerLink="/change-password"
                            >
                                Change your Password
                            </a>
                        </li>

                        <li class="navigation__item">
                            <a
                                href="#"
                                class="navigation__link"
                                (click)="$event.preventDefault(); logout.emit()"
                            >
                                Logout
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div
    [class.overlay--visible]="menuVisible"
    class="overlay"
></div>

import { Component, Input } from '@angular/core';
import { trigger } from '@angular/animations';

import { fadeInAnimation } from "../../animations/fade-in";

@Component({
    selector: 'hd-how-it-works',
    templateUrl: './how-it-works.component.html',
    styleUrls: [ './how-it-works.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class HowItWorksComponent {

    @Input() user: any;

}

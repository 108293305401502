import { CanActivate, Router } from '@angular/router';
import { first, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccountService } from '../services/account.service';

@Injectable({
    providedIn: 'root',
})
export class NotAuthenticatedGuard implements CanActivate {

    constructor(public router: Router) {}

    canActivate(): Observable<boolean> {
        return AccountService.authenticating$
            .asObservable()
            .pipe(
                first(authenticating => !authenticating),
                map(() => !AccountService.isAuthenticated()),
                tap(notAuthenticated => {
                    if (!notAuthenticated) {
                        this.router.navigate(['']);
                    }
                })
            );
    }
}

import { Component } from '@angular/core';
import { trigger } from '@angular/animations';

import { fadeInAnimation } from '../../animations/fade-in';

@Component({
    selector: 'hd-terms-and-conditions-page',
    templateUrl: './terms-and-conditions-page.component.html',
    styleUrls: [ './terms-and-conditions-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class TermsAndConditionsPageComponent {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContainerModule } from '../../components/container/container.module';

import { PrivacyPolicyPageComponent } from './privacy-policy-page.component';
import { SocialIconsModule } from '../../components/social-icons/social-icons.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ContainerModule,
        SocialIconsModule,
    ],
    declarations: [
        PrivacyPolicyPageComponent,
    ],
    exports: [
        PrivacyPolicyPageComponent,
    ],
})
export class PrivacyPolicyPageModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelectAreaPageComponent } from './select-area-page.component';
import { SelectAreaModalModule } from '../../components/select-area-modal/select-area-modal.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SelectAreaModalModule,
    ],
    declarations: [
        SelectAreaPageComponent,
    ],
    exports: [
        SelectAreaPageComponent,
    ],
})
export class SelectAreaPageModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer.component';
import { SocialBlockModule } from '../social-block/social-block.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SocialBlockModule,
    ],
    declarations: [
        FooterComponent,
    ],
    exports: [
        FooterComponent,
    ],
})
export class FooterModule {}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'hd-membership-bar',
    templateUrl: './membership-bar.component.html',
    styleUrls: [ './membership-bar.component.scss' ],
})
export class MembershipBarComponent {

    @Input() logo = '/assets/images/logo-simple.png';

}

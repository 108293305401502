<mc-input-wrapper
    [errorMessage]="errorMessage"
    [invalid]="invalid"
    [label]="label"
    [inputId]="id"
    [information]="information"
>
    <div class="wrapper">

        <input
            autocomplete="new-password"
            class="input"
            [attr.type]="!visible ? 'password' : null"
            [attr.id]="id"
            [class.input--error]="invalid"
            [formControl]="control"
        >

        <a
            href="#"
            class="toggle-visibility"
            [class.toggle-visibility--visible]="visible"
            (click)="$event.preventDefault(); toggleVisibility()"
        ></a>

    </div>

</mc-input-wrapper>

<div
    *ngIf="showRules"
    class="rules"
>

    <div
        [class.rule--complete]="validLength"
        class="rule"
    >
        At least <span class="rule--highlight">8 characters</span>
    </div>

    <div
        [class.rule--complete]="containsUpperLetter && containsLowerLetter"
        class="rule"
    >
        One <span class="rule--highlight">upper letter</span> & <span class="rule--highlight">lower letter</span>
    </div>

    <div
        [class.rule--complete]="containsNumber && containsSpecialCharacter"
        class="rule"
    >
        One <span class="rule--highlight">number</span> & <span class="rule--highlight">special character</span>
    </div>

</div>

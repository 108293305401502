import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger } from '@angular/animations';

import { fadeInAnimation } from '../../animations/fade-in';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'hd-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: [ './login-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class LoginPageComponent {

    error = false;
    accountAlreadyExists = this.route.snapshot.queryParams.accountAlreadyExists || false;
    loggedOut = this.route.snapshot.queryParams.loggedOut || false;
    passwordReset = this.route.snapshot.queryParams.passwordReset || false;

    get emailControl() { return this.form.get('email'); }
    get passwordControl() { return this.form.get('password'); }

    form = new FormGroup({
        email: new FormControl('', [ Validators.required ]),
        password: new FormControl('', [ Validators.required ]),
    }, { updateOn: 'blur' });

    authenticating = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
    ) {}

    onLogin(form: FormGroup) {
        if (form.invalid) {
            form.get('email').markAsTouched();
            form.get('password').markAsTouched();

            return;
        }

        this.authenticating = true;
        this.error = false;

        this.accountService.login(form.value.email, form.value.password)
            .subscribe({
                next: () => {
                    this.authenticating = false;

                    if (!AccountService.stripeSubscriptionActive()) {
                        this.router.navigate([ '/subscribe' ]);

                        return;
                    }

                    this.router.navigate([ this.route.snapshot.queryParams.redirect || '/' ]);
                },
                error: () => {
                    this.authenticating = false;

                    this.error = true;
                },
            });
    }
}

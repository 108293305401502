import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContainerModule } from '../../components/container/container.module';

import { HowItWorksPageComponent } from './how-it-works-page.component';
import { RouterModule } from '@angular/router';
import {HowItWorksModule} from "../../components/how-it-works/how-it-works.module";

@NgModule({
    imports: [
        CommonModule,

        ContainerModule,
        RouterModule,
        HowItWorksModule,
    ],
    declarations: [
        HowItWorksPageComponent,
    ],
    exports: [
        HowItWorksPageComponent,
    ],
})
export class HowItWorksPageModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContainerModule } from '../../components/container/container.module';

import { WalkPageComponent } from './walk-page.component';
import { LoaderModule } from '../../components/loader/loader.module';
import { SplashModule } from '../../components/splash/splash.module';
import { CarouselModule } from '../../components/carousel/carousel.module';
import { EstablishmentBlockModule } from '../../components/establishment-block/establishment-block.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,

        ContainerModule,
        LoaderModule,
        SplashModule,
        CarouselModule,
        EstablishmentBlockModule,
        RouterModule,
    ],
    declarations: [
        WalkPageComponent,
    ],
    exports: [
        WalkPageComponent,
    ],
})
export class WalkPageModule {}

<mc-input-wrapper
    [errorMessage]="errorMessage"
    [invalid]="invalid"
    [inputId]="id"
    [label]="label"
>

    <div class="wrapper">
        <select
            class="input"
            [attr.id]="id"
            [class.input--error]="invalid"
            [disabled]="control.disabled"
            [(ngModel)]="selected"
            [compareWith]="compareWith"
            (change)="onChange(selected)"
            (blur)="onBlur()"
        >

            <option value=""></option>
            <option
                *ngFor="let option of options"
                [ngValue]="option.value"
            >
                {{ option.label }}
            </option>
        </select>
    </div>

</mc-input-wrapper>

import { Component, Input } from '@angular/core';

import { InputComponent } from '../input-component.class';


@Component({
    selector: 'hd-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: [ './password-input.component.scss' ],
})
export class PasswordInputComponent extends InputComponent {

    @Input() showRules = false;
    @Input() set validate(validate) {
        if (validate) {
            this.control.setValidators([
                ...this.control.validator ? [ this.control.validator ] : [],
                ({ value }) => {
                    return (value || '').length >= 8 ? null : { minLength: true };
                },
                ({ value }) => {
                    return /\d/.test(value || '') ? null : { number: true };
                },
                ({ value }) => {
                    return /[a-z]/.test(value || '') ? null : { lowerLetter: true };
                },
                ({ value }) => {
                    return /[A-Z]/.test(value || '') ? null : { upperLetter: true };
                },
                ({ value }) => {
                    return /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value || '') ? null : { specialCharacter: true };
                },
            ]);
        }
    }

    get validLength() { return !this.control.errors || !this.control.errors.minLength; }
    get containsNumber() { return !this.control.errors || !this.control.errors.number; }
    get containsSpecialCharacter() { return !this.control.errors || !this.control.errors.specialCharacter; }
    get containsUpperLetter() { return !this.control.errors || !this.control.errors.upperLetter; }
    get containsLowerLetter() { return !this.control.errors || !this.control.errors.lowerLetter; }

    visible = false;

    toggleVisibility() {
        this.visible = !this.visible;
    }
}

import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hd-container',
    templateUrl: './container.component.html',
    styleUrls: [ './container.component.scss' ],
})
export class ContainerComponent {

    @HostBinding('class.block') @Input() block = false;

}

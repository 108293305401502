import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Area } from '../models/area.model';

@Injectable({
    providedIn: 'root',
})
export class AreaService {

    static readonly SELECTED_KEY = 'be-local.area.selected';

    static areas: Area[];

    static get selected(): Area {
        const areaId = AreaService.getSelectedId();

        return areaId && AreaService.areas?.find(({ _id }) => _id === areaId)
    }

    private static changedSubject$ = new Subject<Area>();

    static get changed$(): Observable<Area> {
        return AreaService.changedSubject$.asObservable();
    };

    constructor(private http: HttpClient) {}

    getAll() {
        if (AreaService.areas) {
            return of(AreaService.areas);
        }

        return this.http.get<Area[]>('https://api.be-local.uk/areas')
            .pipe(tap(areas => AreaService.areas = areas));
    }

    static updateSelected(area: Area) {
        if (AreaService.getSelectedId() === area._id) {
            return;
        }

        AreaService.setSelectedId(area);
        AreaService.changedSubject$.next(AreaService.selected);
    }

    static getSelectedId() {
        return localStorage.getItem(AreaService.SELECTED_KEY);
    }

    static setSelectedId({ _id }: Area) {
        return localStorage.setItem(AreaService.SELECTED_KEY, _id);
    }

}

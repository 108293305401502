<div
    class="card"
    #cardElement
>
    <div class="card-inner">
        <div
            class="card-front"
            [style.background]="this.card.primaryColour || null"
        >
            <div class="logo-wrapper">
                <img
                    class="logo"
                    [attr.src]="card.logo"
                >
            </div>

            <p class="offer">{{ card.offerText }}</p>

            <p
                *ngIf="card.offerAvailabilitySummary"
                class="terms"
            >
                {{ card.offerAvailabilitySummary }}
            </p>

            <p
                *ngIf="card.offerDetails"
                class="details"
            >
                {{ card.offerDetails }}
            </p>

            <div class="bottom">
                <a
                    href="#"
                    class="use-card"
                    (click)="$event.preventDefault(); onUse()"
                >
                    USE DISCOUNT CARD
                </a>
            </div>
        </div>

        <div
            class="card-back"
            [style.background]="card.primaryColour || null"
            #cardBackElement
        >

            <a
                href="#"
                class="close"
                (click)="$event.preventDefault(); onClose()"
            >
                Close
            </a>

            <div class="top">
                <div class="logo-wrapper">
                    <img
                        class="logo"
                        [attr.src]="card.logo"
                    >
                </div>

                <p class="offer">{{ card.offerText }}</p>

                <p
                    *ngIf="card.offerAvailabilitySummary"
                    class="terms"
                >
                    {{ card.offerAvailabilitySummary }}
                </p>

                <p
                    *ngIf="card.offerDetails"
                    class="details"
                >
                    {{ card.offerDetails }}
                </p>

                <div class="detail-row">
                    <span class="detail-row__label">Member<br>Name</span>
                    <span class="detail-row__value">{{ name }}</span>
                </div>

                <div
                    *ngIf="card.onlineDiscountCode"
                    class="detail-row"
                >
                    <span class="detail-row__label">Online<br>Code</span>
                    <span class="detail-row__value">{{ card.onlineDiscountCode }}</span>
                </div>
            </div>

            <div class="footer-row">
                <span class="date">{{ date }}</span>
                <span class="time">{{ time }}</span>
            </div>

            <div class="footer-row footer-row--padded">
                <img
                    src="/assets/images/discount-card-logo.png"
                    class="footer-logo"
                >
            </div>
        </div>
    </div>

</div>

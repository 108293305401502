import { Component, ViewChild } from '@angular/core';
import { iif, of } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { trigger } from '@angular/animations';

import { AccountService } from '../../services/account.service';
import { fadeInAnimation } from '../../animations/fade-in';

@Component({
    selector: 'hd-subscribe-page',
    templateUrl: './subscribe-page.component.html',
    styleUrls: [ './subscribe-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class SubscribePageComponent {

    user$ = AccountService.user$;
    paymentError = '';
    promotionalCodeError = '';
    processingSubscription = false;
    card: any;

    get subscriptionActive () { return AccountService.stripeSubscriptionActive(); }

    @ViewChild('subscriptionForm') set subscriptionForm(element) {
        if (element) {
            this.setupStripe();
        }
    }

    constructor(
        private router: Router,
        private accountService: AccountService,
    ) {}

    private setupStripe() {
        const elements = AccountService.getStripeInstance(AccountService.user$.value['custom:stripeAccountId']).elements();
        const cardElement = elements.create('card', { style: {} });

        cardElement.mount('#card-element');

        cardElement.on('change', event => {
            this.setPaymentError(event);
        });

        this.card = cardElement;
    }

    onSetupSubscription(promotionalCode?: string) {
        this.processingSubscription = true;

        iif(
            () => Boolean(promotionalCode),
            this.accountService.getPromotionalCode(promotionalCode),
            of({}),
        )
            .pipe(
                switchMap(({ promotionalCodeId }) => {
                    return this.accountService.createPaymentMethod(this.card, promotionalCodeId)
                })
            )
            .subscribe({
                 next: () => {
                    this.processingSubscription = false;
                },
                error: error => {
                    this.processingSubscription = false;

                    this.setPaymentError(error);
                },
            });
    }

    setPaymentError(event) {
        if (event.error && event.error.code === 'INVALID_PROMOTIONAL_CODE') {
            this.promotionalCodeError = event.error ? event.error.message : '';
        } else {
            this.paymentError = event.error ? event.error.message : '';
        }
    }
}

<label
    *ngIf="label"
    class="label"
    [attr.for]="inputId"
>
    {{ label }}

    <span
        *ngIf="reason"
        class="reason"
    >
        ({{ reason }})
    </span>
</label>

<ng-content></ng-content>

<ng-container *ngIf="information">

    <a
        *ngIf="information"
        class="information-icon"
        href="#"
        (click)="$event.preventDefault(); toggleInformationPopup()"
        #informationIcon
    ></a>

    <div
        class="information-popup"
        [class.information-popup--visible]="informationPopupVisible"
    >
        {{ information }}
    </div>

</ng-container>

<span
    *ngIf="invalid && errorMessage"
    class="error"
>
    {{ errorMessage }}
</span>

import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';

import { AccountService } from '../../services/account.service';
import { BaseComponent } from '../base-component.class';
import { DiscountCard } from '../../models/discount-card.model';

@Component({
    selector: 'hd-discount-card',
    templateUrl: './discount-card.component.html',
    styleUrls: [ './discount-card.component.scss' ],
})
export class DiscountCardComponent extends BaseComponent implements OnInit {

    supportsPassive = false;

    @HostBinding('class.flipped') flipped = false;

    @Input() card: DiscountCard;
    @Input() date: string;
    @Input() time: string;

    name = `${AccountService.getTokenContents().given_name} ${AccountService.getTokenContents().family_name}`;

    @ViewChild('cardElement') cardElement: ElementRef;
    @ViewChild('cardBackElement') cardBackElement: ElementRef;

    ngOnInit() {
        window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
            get: () => {
                this.supportsPassive = true;
            }
        }));
    }

    onUse() {
        this.flipped = true;

        this.disableScroll();

        const { left, top, width, height } = this.cardBackElement.nativeElement.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const translateX = ((windowWidth - width) / 2) - left;
        const translateY = ((windowHeight - height) / 2) - top;

        this.cardElement.nativeElement.style.width = width + 'px';
        this.cardElement.nativeElement.style.transform = `translate(${translateX}px, ${translateY}px)`;
        this.cardElement.nativeElement.style.zIndex = 10000000;

        if (!(window as any).gtag) {
            return;
        }

        (window as any).gtag('event', 'click', {
            event_category: `Wallet Page`,
            event_label: this.card.name,
            event_action: `Click Use Discount Card`,
        });
    }

    onClose() {
        this.flipped = false;

        this.cardElement.nativeElement.style.transform = `translate(0, 0)`;

        setTimeout(() => {
            this.cardElement.nativeElement.style.zIndex = 1;
            this.cardElement.nativeElement.style.width = 'auto';

            this.enableScroll();
        }, 500);
    }

    disableScroll() {
        const wheelOpt = this.supportsPassive ? { passive: false } : false;
        const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

        window.addEventListener('DOMMouseScroll', this.preventDefault, false);
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt);
        window.addEventListener('touchmove', this.preventDefault, wheelOpt);
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }

    enableScroll() {
        const wheelOpt = this.supportsPassive ? { passive: false } : false;
        const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt as any);
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt as any);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }

    preventDefault(e) {
        e.preventDefault();
    }

    preventDefaultForScrollKeys(e) {
        if ({ 37: 1, 38: 1, 39: 1, 40: 1 }[e.keyCode]) {
            this.preventDefault(e);

            return false;
        }
    }
}

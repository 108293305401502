import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hd-social-section',
    templateUrl: './social-section.component.html',
    styleUrls: [ './social-section.component.scss' ],
})
export class SocialSectionComponent {

    @HostBinding('class.block') @Input() block = false;

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ApiRequestService {

    static cache = {};

    constructor(protected http: HttpClient) {}

    get<T>(url, { useCache = false }: { useCache?: boolean } = {}): Observable<T> {
        if (useCache && ApiRequestService.cache[url]) {
            return of(ApiRequestService.cache[url]);
        }

        return this.http.get<T>(url)
            .pipe(tap(response => ApiRequestService.cache[url] = response));
    }

}
import { animate, style, transition } from '@angular/animations';

export const slideFromBottomAnimation = [
    transition(':enter', [
        style({ transform: 'translateY(150%)' }),
        animate('250ms ease-out', style({ transform: 'translateY(0)' }))
    ]),
    transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('250ms ease-out', style({ transform: 'translateY(150%)' }))
    ]),
];

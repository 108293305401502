import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import { trigger } from '@angular/animations';

import { BaseComponent } from '../../components/base-component.class';
import { Walk } from '../../models/walk.model';
import { WalkService } from '../../services/walk.service';
import { fadeInAnimation } from '../../animations/fade-in';
import { EstablishmentSimple } from '../../models/establishment-simple.model';

@Component({
    selector: 'hd-walk-page',
    templateUrl: './walk-page.component.html',
    styleUrls: [ './walk-page.component.scss' ],
    animations: [
        trigger('fadeIn', fadeInAnimation),
    ],
})
export class WalkPageComponent extends BaseComponent implements OnInit {

    loading = true;
    routesText: string;
    walk: Walk;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private walkService: WalkService,
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;

        this.walkService.getOne(this.route.snapshot.params.slug)
            .pipe(delay(50)) // Delay allows loader to show when cached
            .subscribe(walk => {
                this.walk = walk;
                this.routesText = this.walk.routes
                    ? `${this.walk.routes.map(({ mileage }) => mileage).join(' or ')} miles`
                    : '';
                this.loading = false;
            });
    }

    onViewEstablishment({ slug }: EstablishmentSimple) {
        this.router.navigate([ '/establishments', slug ]);
    }
}

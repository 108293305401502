import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hd-carousel-item',
    templateUrl: './carousel-item.component.html',
    styleUrls: [ './carousel-item.component.scss' ],
})
export class CarouselItemComponent {

    @HostBinding('class.bottom') @Input() bottom = false;
    @HostBinding('class.wider') @Input() wider = false;

}

<hd-loader *ngIf="loading"></hd-loader>

<div
    *ngIf="!loading"
    [@fadeIn]
>
    <hd-splash
        [featureText]="routesText"
        [image]="walk.headerImage"
        [title]="walk.title"
    ></hd-splash>

    <div
        class="html"
        [innerHTML]="walk.content"
    ></div>

    <div
        *ngIf="walk.establishments?.length"
        class="panel panel--dark"
    >

        <div class="panel__container">

            <h2 class="panel__title">Featured Independents</h2>

            <hd-carousel>

                <hd-carousel-item
                    *ngFor="let establishment of walk.establishments"
                    [wider]="true"
                >

                    <hd-establishment-block
                        [establishment]="establishment"
                        (view)="onViewEstablishment($event)"
                    ></hd-establishment-block>

                </hd-carousel-item>

            </hd-carousel>

        </div>

    </div>

    <div class="panel panel--center">
        <div class="panel__container">
            <img
                class="panel__logo"
                src="assets/images/logos/outdooractive.png"
            >

            <p class="text">We plot all of our walks on the Outdooractive app so they’re easy for you to follow.</p>

            <a
                class="link"
                href="http://onelink.to/46adte"
                target="_blank"
            >
                Download the Outdooractive app
            </a>

            <a
                class="link link--last"
                href="https://homedine.medium.com/everything-you-need-to-know-before-you-do-a-home-dine-solihull-walk-d76b4c0a6b69"
                target="_blank"
            >
                How to do a be local walk
            </a>

            <a
                class="button"
                *ngFor="let route of walk.routes"
                [attr.href]="route.link"
                target="_blank"
            >
                View {{ route.title }} walk
            </a>
        </div>
    </div>
</div>

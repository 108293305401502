import { Component, Input } from '@angular/core';

@Component({
    selector: 'hd-splash',
    templateUrl: './splash.component.html',
    styleUrls: [ './splash.component.scss' ],
})
export class SplashComponent {

    imageLoaded = false;

    @Input() description: string;
    @Input() image: string;
    @Input() featureText: string;
    @Input() title: string;

    onLoadImage() {
        this.imageLoaded = true;
    }
}
